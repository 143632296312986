import { Children, useCallback, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import css from '@styled-system/css';
import { CSSTransition } from 'react-transition-group';
import { ChevronDown } from 'react-dripicons';

import Box from 'humanity/primitives/box';
import { accordionProps } from 'humanity/components/accordion/accordion.types';
import TextButton from 'humanity/primitives/textButton';
import Flex from 'humanity/primitives/flex';
import Button from 'humanity/primitives/button';

const StyledTextButton = styled(TextButton)(
  css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  })
);

const AccordionHeaderInner = styled(Box)(
  css({
    flexGrow: 1,
    height: '100%',
    textAlign: 'left',
  })
);

const IconWrapper = styled(Flex)(({ isExpanded }) =>
  css({
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    pr: 1,
    pb: '1px',
    '& > svg': {
      transform: isExpanded ? 'rotate(180deg)' : 'none',
      transition: 'all 150ms linear',
    },
  })
);

const AccordionContent = styled(Box)(({ expandedByDefault, refHeight }) =>
  css({
    visibility: expandedByDefault ? 'visible' : 'hidden',
    height: expandedByDefault ? `auto` : 0,
    overflow: expandedByDefault ? 'visible' : 'hidden',
    opacity: expandedByDefault ? 1 : 0,
    '&.accordion-content-enter': {
      visibility: 'visible',
      height: 0,
      overflow: 'hidden',
      opacity: 0.1,
    },
    '&.accordion-content-enter.accordion-content-enter-active': {
      visibility: 'visible',
      mt: 1,
      height: `${refHeight}px`,
      opacity: 1,
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.25, 1) 0ms',
    },
    '&.accordion-content-enter-done': {
      visibility: 'visible',
      mt: 1,
      height: 'auto',
      opacity: 1,
    },
    '&.accordion-content-exit': {
      visibility: 'visible',
      mt: 1,
      height: `${refHeight}px`,
      opacity: 1,
    },
    '&.accordion-content-exit.accordion-content-exit-active': {
      visibility: 'visible',
      height: 0,
      overflow: 'hidden',
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.25, 1) 0ms',
    },
    '&.accordion-content-exit-done': {
      visibility: 'hidden',
      height: 0,
      overflow: 'hidden',
    },
  })
);
/**
 * Expects an array of children nodes. The first child will be used as the
 * accordion "button". Remaining children will be wrapped in the expanding
 * content panel.
 */
const Accordion = ({
  baseId,
  children,
  buttonComponent = 'TextButton',
  buttonProps = {},
  expandedByDefault = false,
  iconSize = 24,
  iconColor = 'blue100',
  onChange = () => {},
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(expandedByDefault);
  const nodeRef = useRef(null);
  const theme = useTheme();

  const [header, ...content] = Children.toArray(children);

  const handleChange = useCallback(
    (event) => {
      setIsExpanded(!isExpanded);

      if (typeof onChange === 'function') {
        onChange(event, !isExpanded);
      }
    },
    [isExpanded, onChange]
  );

  const baseButtonProps = {
    id: `${baseId}_header`,
    'data-testid': 'AccordionHeader',
    'aria-expanded': isExpanded,
    'aria-controls': `${baseId}_content`,
    onClick: handleChange,
    isExpanded,
  };

  return (
    <Box data-testid="Accordion" {...rest}>
      {buttonComponent === 'TextButton' && (
        <StyledTextButton {...baseButtonProps} {...buttonProps}>
          <AccordionHeaderInner>{header}</AccordionHeaderInner>
          <IconWrapper isExpanded={isExpanded}>
            <ChevronDown size={iconSize} color={theme.colors[iconColor]} />
          </IconWrapper>
        </StyledTextButton>
      )}
      {buttonComponent === 'Button' && (
        <Button width={1} {...baseButtonProps} {...buttonProps}>
          {header}
        </Button>
      )}

      <CSSTransition
        in={isExpanded}
        timeout={300}
        nodeRef={nodeRef}
        classNames="accordion-content"
      >
        <AccordionContent
          id={`${baseId}_content`}
          data-testid="AccordionContent"
          aria-labelledby={`${baseId}_header`}
          role="region"
          expandedByDefault={expandedByDefault}
          aria-hidden={!isExpanded}
          ref={nodeRef}
          refHeight={nodeRef.current?.scrollHeight}
        >
          {content}
        </AccordionContent>
      </CSSTransition>
    </Box>
  );
};

Accordion.propTypes = {
  ...accordionProps,
};

export default Accordion;
