import styled from 'styled-components';
import {
  variant,
  compose,
  space,
  layout,
  color,
  typography,
  SpaceProps,
  LayoutProps,
  ColorProps,
  TypographyProps,
} from 'styled-system';
import css from '@styled-system/css';

import { VariantProps } from '../../../types/variantProps';

export const headingVariants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'testimonial',
] as const;

type Props = SpaceProps &
  LayoutProps &
  ColorProps &
  TypographyProps &
  VariantProps<(typeof headingVariants)[number]>;

const Heading = styled.h2.attrs<Props>((props) => ({
  ...props,
  variant: props.variant ?? 'h2',
}))<Props>(
  css({
    fontFamily: 'heading',
    fontWeight: 'heading',
    color: 'blue100',
  }),
  variant({
    variants: {
      h1: {
        fontSize: 'h1',
        lineHeight: 'h1',
      },
      h2: {
        fontSize: 'h2',
        lineHeight: 'h2',
      },
      h3: {
        fontSize: 'h3',
        lineHeight: 'h3',
      },
      h4: {
        fontSize: 'h4',
        lineHeight: 'h4',
      },
      h5: {
        fontSize: 'h5',
        lineHeight: 'h5',
      },
      h6: {
        fontSize: 'h6',
        lineHeight: 'h6',
      },
      testimonial: {
        fontFamily: 'rufina',
        fontSize: 'testimonialLarge',
        lineHeight: 'testimonialLarge',
      },
    },
  }),
  compose(space, layout, color, typography)
);

Heading.displayName = 'Heading';

export default Heading;
