/**
 * Generates the URL for the given Contentful "resource"
 * @param {{ url: String, __typename: String, slug: String}} resource A Contentful "resource" object
 * @returns The URL for the given resource
 */
const getResourceUrl = (resource) => {
  const urlPaths = {
    LearningCenterArticle: '/learn/articles',
    LearningCenterCalculator: '/learn/calculators',
    LearningCenterGuide: '/learn/guides',
    LearningCenterWebinar: '/learn/webinars',
    CustomerStory: '/customer-stories',
  };

  const resourceUrl =
    resource.url ?? `${urlPaths[resource.__typename]}/${resource.slug}/`;

  return resourceUrl;
};

export default getResourceUrl;
