import styled from 'styled-components';
import css from '@styled-system/css';

import Link from 'humanity/primitives/link';
import {
  headerNavbarLinkProps,
  headerNavbarLinkDefaultProps,
} from 'humanity/components/headerNavbarLink/headerNavbarLink.types';

const HeaderNavbarLink = styled(Link)(({ color }) =>
  css({
    position: 'relative',
    fontSize: 'bodySmall',
    textDecoration: 'none',
    transition: 'all 0.3s',
    color,
    '&:before': {
      position: 'absolute',
      width: 0,
      height: 2,
      bottom: '-0.2em',
      bg: color,
      transition: 'all 0.3s cubic-bezier(0.77, 0, 0.175, 1)',
      content: '""',
    },
    '&:hover': {
      textDecoration: 'none',
      '&:before': {
        width: '100%',
      },
    },
  })
);

HeaderNavbarLink.displayName = 'HeaderNavbarLink';

HeaderNavbarLink.propTypes = {
  ...headerNavbarLinkProps,
};

HeaderNavbarLink.defaultProps = {
  ...headerNavbarLinkDefaultProps,
};

export default HeaderNavbarLink;
