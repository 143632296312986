import PropTypes from 'prop-types';

import { customerStoryPreviewType } from 'types/customerStories/customerStory.types';
import { learningCenterArticlePreviewType } from 'types/learningCenter/learningCenterArticle.types';
import { learningCenterCalculatorPreviewType } from 'types/learningCenter/learningCenterCalculator.types';
import { learningCenterGuidePreviewType } from 'types/learningCenter/learningCenterGuide.types';

export const headerNavContentLinkProps = {
  item: PropTypes.oneOfType([
    customerStoryPreviewType,
    learningCenterArticlePreviewType,
    learningCenterCalculatorPreviewType,
    learningCenterGuidePreviewType,
  ]),
};

export const headerNavContentLinkType = PropTypes.shape({});
