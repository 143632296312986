export const headerNavStandard = {
  bgColor: 'white',
  logoUrl: '/',
  mobileLogo: {
    url: 'https://images.ctfassets.net/tj9jxg7kaxby/PGqh8AtQli1FTGu0rhU3S/2f2c4f5dfc70d88584401d39618dfe49/hi-logomark.svg',
    width: 27,
    height: 40,
  },
  desktopLogo: {
    url: 'https://images.ctfassets.net/tj9jxg7kaxby/4xN7JhmwCRuz3y9M8L8CF7/5c244c52cfc982bd1a348a80dc35d43b/hi-logo-new.svg',
    width: 132,
    height: 40,
  },
  navigationItemsCollection: {
    items: [
      {
        __typename: 'Link',
        sys: {
          id: '5Th5cTa07uZBtCom5KE1xy',
        },
        label: 'Pricing',
        url: '/pricing/',
      },
      {
        __typename: 'HeaderNavMenu',
        sys: {
          id: '126uTenVr2jdOrLyNKwD0P',
        },
        title: 'Retirement Solutions',
        sectionsCollection: {
          items: [
            {
              __typename: 'HeaderNavLinkList',
              sys: {
                id: '5rZu2aAV735tSV7VoCP3MJ',
              },
              title: 'Retirement Plans',
              seeAllLink: null,
              linksCollection: {
                items: [
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '1XdEm1srmNpmduKeDbkgem',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: '401(k)',
                    url: '/lp/401k-for-small-businesses/',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '17ccryh865li2vChFQutbT',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: '403(b)',
                    url: '/lp/start-your-403b',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '4f8R49XM1Sx2vhceSAAtLQ',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: 'Safe Harbor',
                    url: '/lp/safe-harbor/',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                ],
              },
            },
            {
              __typename: 'HeaderNavLinkList',
              sys: {
                id: '4Uv8VJBD2H5SPZhx1x6ujm',
              },
              title: 'Our Solution',
              seeAllLink: {
                sys: {
                  id: '2s0PIThuJRtHh8v4qZFGFg',
                },
                label: 'See All',
                url: '/solutions/',
              },
              linksCollection: {
                items: [
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '1XdEm1srmNpmduKeDbkgem',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: '401(k)',
                    url: '/lp/401k-for-small-businesses/',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '17ccryh865li2vChFQutbT',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: '403(b)',
                    url: '/lp/start-your-403b',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '4f8R49XM1Sx2vhceSAAtLQ',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: 'Safe Harbor',
                    url: '/lp/safe-harbor/',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                ],
              },
            },
            {
              __typename: 'HeaderNavCtaBlock',
              sys: {
                id: 'NfORPicnfXHFZ5xOP5map',
              },
              backgroundColor: 'brightBlue10',
              heading: 'We integrate with more than 200 payroll providers',
              paragraph:
                'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ',
              cta: {
                sys: {
                  id: '6N2dxLIwOpDKd0fKQLANy9',
                },
                type: 'button',
                variant: 'secondary',
                label: 'Our Payroll Integrations',
                url: '/signup/',
                openInNewTab: null,
              },
            },
          ],
        },
      },
      {
        __typename: 'HeaderNavMenu',
        sys: {
          id: '3QEIOGpcxIb6Q7wIhgNMnP',
        },
        title: 'Who We Serve',
        sectionsCollection: {
          items: [
            {
              __typename: 'HeaderNavLinkList',
              sys: {
                id: '5rZu2aAV735tSV7VoCP3MJ',
              },
              title: 'Retirement Plans',
              seeAllLink: null,
              linksCollection: {
                items: [
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '1XdEm1srmNpmduKeDbkgem',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: '401(k)',
                    url: '/lp/401k-for-small-businesses/',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '17ccryh865li2vChFQutbT',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: '403(b)',
                    url: '/lp/start-your-403b',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '4f8R49XM1Sx2vhceSAAtLQ',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: 'Safe Harbor',
                    url: '/lp/safe-harbor/',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                ],
              },
            },
            {
              __typename: 'HeaderNavLinkList',
              sys: {
                id: '4Uv8VJBD2H5SPZhx1x6ujm',
              },
              title: 'Our Solution',
              seeAllLink: {
                sys: {
                  id: '2s0PIThuJRtHh8v4qZFGFg',
                },
                label: 'See All',
                url: '/solutions/',
              },
              linksCollection: {
                items: [
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '1XdEm1srmNpmduKeDbkgem',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: '401(k)',
                    url: '/lp/401k-for-small-businesses/',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '17ccryh865li2vChFQutbT',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: '403(b)',
                    url: '/lp/start-your-403b',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '4f8R49XM1Sx2vhceSAAtLQ',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: 'Safe Harbor',
                    url: '/lp/safe-harbor/',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                ],
              },
            },
            {
              __typename: 'HeaderNavContentSpotlight',
              sys: {
                id: '36Rd00dB3foMVQzl3NVCKf',
              },
              backgroundColor: 'yellow10',
              title: 'Customer Stories',
              seeAllLink: {
                sys: {
                  id: '7b71X6ry6k0E1Set9K1ES0',
                },
                label: 'See All',
                url: '/customer-stories/',
              },
              linksCollection: {
                items: [
                  {
                    __typename: 'CustomerStory',
                    sys: {
                      id: 'DxvGDiGNlpudGaWNIBBWM',
                    },
                    title:
                      'How Kevin Cradock Builders helps construct a bright future for their team',
                    slug: 'kevin-cradock-builders-case-study',
                    image: {
                      url: 'https://images.ctfassets.net/tj9jxg7kaxby/6dn3bqFCm04ZzoOVWCryn8/5f17a915dd121a5870092b26540bb122/cradock_eliot_school.jpg',
                    },
                    excerpt:
                      'The construction industry has largely been underserved by legacy 401(k) providers. It wasn’t until Kevin Cradock Builders came across Human Interest that retirement benefits became attainable for the high-end home builder.',
                  },
                  {
                    __typename: 'CustomerStory',
                    sys: {
                      id: '3Rdoz14bDZajbQBj0RNG1Q',
                    },
                    title:
                      'Sexy Pizza aims to transform the restaurant industry with 401(k) benefits',
                    slug: 'sexy-pizza-case-study',
                    image: {
                      url: 'https://images.ctfassets.net/tj9jxg7kaxby/5uNaGr7JgFv0oz1NG3ft19/094a28828c67845239f4005ac52bf755/Sexy-Pizza-Shoot-2-Websize-40-1__1_.jpg',
                    },
                    excerpt:
                      'Just as they serve as a beacon for community engagement, Sexy Pizza takes its responsibilities as a retirement benefits trendsetter seriously.',
                  },
                  {
                    __typename: 'CustomerStory',
                    sys: {
                      id: '5OxFLBQtXgdcTQrsKh35q1',
                    },
                    title:
                      'Easy Mile Fitness makes financial wellness a reality for its growing team',
                    slug: 'easy-mile-fitness-big-moment',
                    image: {
                      url: 'https://images.ctfassets.net/tj9jxg7kaxby/4JDJLuYs1pmBIKvhQnuHO1/844a39c0f6528b8050c0a90a36d48b89/Easy_Mile_Fitness__1_.jpg',
                    },
                    excerpt:
                      'At Easy Mile Fitness, (a Planet Fitness franchisee operating 15 locations), health and wellness is about inclusion. Learn how the company takes a similar approach to financial wellness.',
                  },
                ],
              },
            },
          ],
        },
      },
      {
        __typename: 'HeaderNavMenu',
        sys: {
          id: '365Z2kgYlpUZHwOkSF2K85',
        },
        title: 'Payroll Integrations',
        sectionsCollection: {
          items: [
            {
              __typename: 'HeaderNavCtaBlock',
              sys: {
                id: 'NfORPicnfXHFZ5xOP5map',
              },
              backgroundColor: 'brightBlue10',
              heading: 'We integrate with more than 200 payroll providers',
              paragraph:
                'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ',
              cta: {
                sys: {
                  id: '6N2dxLIwOpDKd0fKQLANy9',
                },
                type: 'button',
                variant: 'secondary',
                label: 'Our Payroll Integrations',
                url: '/signup/',
                openInNewTab: null,
              },
            },
            {
              __typename: 'HeaderNavLinkList',
              sys: {
                id: '3wYE7fGFK6SAMAvcMKDa3m',
              },
              title: 'Payroll Integrations',
              seeAllLink: null,
              linksCollection: {
                items: [
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '3BS4fFWWgczCa4nssSKVKu',
                    },
                    image: {
                      sys: {
                        id: '2BpZF8eUoj8c3np5Yg26iU',
                      },
                      width: 11,
                      height: 17,
                      altText: 'Zenefits',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/6yqEomrCtW89XrY9nEHycw/d49bbfd324ac94e186397c958482cf14/Zenefits.svg',
                      },
                    },
                    title: 'Zenefits',
                    url: '/payrolls/zenefits/',
                    description: null,
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '6Jj3rthoxbWh6IUyVdTjdO',
                    },
                    image: {
                      sys: {
                        id: '7d5weeb9TwdMD9TBB0d20O',
                      },
                      width: 16,
                      height: 15,
                      altText: 'Image',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/16DLDg14hYMR6odPx1HYBq/9d3db1d9eaeb58b3090f0d469f078c9b/Namely.svg',
                      },
                    },
                    title: 'Namely',
                    url: '/payrolls/namely/',
                    description: null,
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: 'AMBEUTsliuoio1DmOvbGo',
                    },
                    image: {
                      sys: {
                        id: '4gVLYhNFVv5NtC7wKreYKF',
                      },
                      width: 16,
                      height: 15,
                      altText: 'QuickBooks',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/6v0010hCpQ1fw7uICoId9K/7bfd4569fde8b12a4c260b24ddbe29a0/Quickbooks.svg',
                      },
                    },
                    title: 'QuickBooks',
                    url: '/payrolls/quickbooks/',
                    description: null,
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '4CVInN3lizF5jlHL56M2qN',
                    },
                    image: {
                      sys: {
                        id: '28rV5ksg3idsErcCLgNudJ',
                      },
                      width: 16,
                      height: 15,
                      altText: 'Absolute Payrolls',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/n3Jy9H2XSlbEiRowxY2sZ/0fa5a3306fed48cc8c8b2984b4db63a8/Absolute_Payrolls.svg',
                      },
                    },
                    title: 'Absolute Payrolls',
                    url: '/payrolls/absolute-payrolls/',
                    description: null,
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '1RuWYUXZDmutugsMu5qn19',
                    },
                    image: {
                      sys: {
                        id: '5oT2cv4Gn0RI0S8JQVG1DL',
                      },
                      width: 16,
                      height: 16,
                      altText: 'Paylocity',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/4M80oEZvZaNQ0OBl1cFfTh/e6d22a0a361a8d53e0bd4bcd66414d49/Paylocity.svg',
                      },
                    },
                    title: 'Paylocity',
                    url: '/payrolls/paylocity/',
                    description: null,
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '1H0E93RCRSg9EkzBRsFvE1',
                    },
                    image: {
                      sys: {
                        id: '633lWxw9v2p1A9u029F2Lv',
                      },
                      width: 21,
                      height: 9,
                      altText: 'ADP',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/695cI0FmheHvEIHjNLwlGI/230e3a58e58d06b902b49096752cd35c/adp_logo.png',
                      },
                    },
                    title: 'ADP',
                    url: '/payrolls/adp/',
                    description: null,
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '3lepGAbg9VTO37aKdu3E32',
                    },
                    image: {
                      sys: {
                        id: '3GIFTMrKKivDQwD7ClyX1H',
                      },
                      width: 16,
                      height: 16,
                      altText: 'Fingercheck',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/3G8Vr6unUvqc4Owx8xLM31/67b3df2503d6ece77b72975ca8625f0b/Fingercheck.svg',
                      },
                    },
                    title: 'Fingercheck',
                    url: '/payrolls/fingercheck/',
                    description: null,
                  },
                  {
                    __typename: 'Link',
                    sys: {
                      id: '3mlKK46vKn2eY6pgwIKofG',
                    },
                    label: 'See All Payrolls',
                    url: '/payrolls/',
                  },
                ],
              },
            },
          ],
        },
      },
      {
        __typename: 'HeaderNavMenu',
        sys: {
          id: 'uEQk1u4KUVvriNRGvDHKn',
        },
        title: 'Learn',
        sectionsCollection: {
          items: [
            {
              __typename: 'HeaderNavContentSpotlight',
              sys: {
                id: '36Rd00dB3foMVQzl3NVCKf',
              },
              backgroundColor: 'yellow10',
              title: 'Customer Stories',
              seeAllLink: {
                sys: {
                  id: '7b71X6ry6k0E1Set9K1ES0',
                },
                label: 'See All',
                url: '/customer-stories/',
              },
              linksCollection: {
                items: [
                  {
                    __typename: 'CustomerStory',
                    sys: {
                      id: 'DxvGDiGNlpudGaWNIBBWM',
                    },
                    title:
                      'How Kevin Cradock Builders helps construct a bright future for their team',
                    slug: 'kevin-cradock-builders-case-study',
                    image: {
                      url: 'https://images.ctfassets.net/tj9jxg7kaxby/6dn3bqFCm04ZzoOVWCryn8/5f17a915dd121a5870092b26540bb122/cradock_eliot_school.jpg',
                    },
                    excerpt:
                      'The construction industry has largely been underserved by legacy 401(k) providers. It wasn’t until Kevin Cradock Builders came across Human Interest that retirement benefits became attainable for the high-end home builder.',
                  },
                  {
                    __typename: 'CustomerStory',
                    sys: {
                      id: '3Rdoz14bDZajbQBj0RNG1Q',
                    },
                    title:
                      'Sexy Pizza aims to transform the restaurant industry with 401(k) benefits',
                    slug: 'sexy-pizza-case-study',
                    image: {
                      url: 'https://images.ctfassets.net/tj9jxg7kaxby/5uNaGr7JgFv0oz1NG3ft19/094a28828c67845239f4005ac52bf755/Sexy-Pizza-Shoot-2-Websize-40-1__1_.jpg',
                    },
                    excerpt:
                      'Just as they serve as a beacon for community engagement, Sexy Pizza takes its responsibilities as a retirement benefits trendsetter seriously.',
                  },
                  {
                    __typename: 'CustomerStory',
                    sys: {
                      id: '5OxFLBQtXgdcTQrsKh35q1',
                    },
                    title:
                      'Easy Mile Fitness makes financial wellness a reality for its growing team',
                    slug: 'easy-mile-fitness-big-moment',
                    image: {
                      url: 'https://images.ctfassets.net/tj9jxg7kaxby/4JDJLuYs1pmBIKvhQnuHO1/844a39c0f6528b8050c0a90a36d48b89/Easy_Mile_Fitness__1_.jpg',
                    },
                    excerpt:
                      'At Easy Mile Fitness, (a Planet Fitness franchisee operating 15 locations), health and wellness is about inclusion. Learn how the company takes a similar approach to financial wellness.',
                  },
                ],
              },
            },
            {
              __typename: 'HeaderNavLinkList',
              sys: {
                id: '4Uv8VJBD2H5SPZhx1x6ujm',
              },
              title: 'Our Solution',
              seeAllLink: {
                sys: {
                  id: '2s0PIThuJRtHh8v4qZFGFg',
                },
                label: 'See All',
                url: '/solutions/',
              },
              linksCollection: {
                items: [
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '1XdEm1srmNpmduKeDbkgem',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: '401(k)',
                    url: '/lp/401k-for-small-businesses/',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '17ccryh865li2vChFQutbT',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: '403(b)',
                    url: '/lp/start-your-403b',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '4f8R49XM1Sx2vhceSAAtLQ',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: 'Safe Harbor',
                    url: '/lp/safe-harbor/',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                ],
              },
            },
            {
              __typename: 'HeaderNavLinkList',
              sys: {
                id: '5rZu2aAV735tSV7VoCP3MJ',
              },
              title: 'Retirement Plans',
              seeAllLink: null,
              linksCollection: {
                items: [
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '1XdEm1srmNpmduKeDbkgem',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: '401(k)',
                    url: '/lp/401k-for-small-businesses/',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '17ccryh865li2vChFQutbT',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: '403(b)',
                    url: '/lp/start-your-403b',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                  {
                    __typename: 'HeaderNavLink',
                    sys: {
                      id: '4f8R49XM1Sx2vhceSAAtLQ',
                    },
                    image: {
                      sys: {
                        id: '29AWC9BuZZeLPkgMpwVwZk',
                      },
                      width: 24,
                      height: 24,
                      altText: 'Upward Graph',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                      },
                    },
                    title: 'Safe Harbor',
                    url: '/lp/safe-harbor/',
                    description: 'Amet minim mollit non deserunt ullamco est sit',
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  },
  cta: {
    __typename: 'Cta',
    sys: {
      id: '20J22pllN42vrKs3mQGY42',
    },
    type: 'button',
    variant: 'primary',
    label: 'Get Started',
    url: '/signup/',
    openInNewTab: null,
  },
  secondaryCta: {
    __typename: 'Cta',
    sys: {
      id: '3q2vliWs3SRJGiAycPvgmH',
    },
    type: 'button',
    variant: 'outline',
    label: 'Log In',
    url: 'https://app.humaninterest.com/login',
    openInNewTab: null,
  },
};
