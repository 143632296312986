export default {
  sys: {
    id: '5Dp0YOtO45t9vruD0JwAWO',
  },
  footerNavigation: {
    sys: {
      id: '7J3wfQgUOgDW5pP2eicESY',
    },
    copyrightInfo: {
      json: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: '© 2024 Human Interest, Inc. ',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: 'https://humaninterest.com/disclosures/',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: 'Disclosures',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value: '',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: '655 Montgomery Street, Suite 1800',
                marks: [],
                data: {},
              },
              {
                nodeType: 'text',
                value: '<br/>',
                marks: [
                  {
                    type: 'code',
                  },
                ],
                data: {},
              },
              {
                nodeType: 'text',
                value: 'San Francisco, California 94111',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Human Interest Inc. is an affordable, full-service 401(k) and 403(b) provider that seeks to make it easy for small and medium-sized businesses to assist their employees with investing for retirement. For more information, please visit ',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: 'https://www.humaninterest.com',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: 'humaninterest.com',
                    marks: [
                      {
                        type: 'underline',
                      },
                    ],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value:
                  '. Investment Advisory services are provided through Human Interest Advisors LLC, a Registered Investment Adviser and subsidiary of Human Interest Inc. For more information on our investment advisory services, please visit ',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: 'https://www.humaninterest.com/hia/',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: 'https://www.humaninterest.com/hia/',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value: '.',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'This content has been prepared for informational purposes only, and should not be construed as tax, legal, or individualized investment advice. Human Interest Advisors LLC does not provide tax or legal advice. Consult an appropriate professional regarding your situation. The views expressed are subject to change. In the event third-party data and/or statistics are used, they have been obtained from sources believed to be reliable; however, we cannot guarantee their accuracy or completeness. Investing involves risk, including risk of loss. Past performance does not guarantee future results. This site is protected by reCAPTCHA and the Google ',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: 'https://policies.google.com/privacy',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: 'Privacy Policy',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value: ' and ',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: 'https://policies.google.com/terms',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: 'Terms of Service',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value: ' apply.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    contactSectionHeading: "Let's Talk",
    contactSectionBlocksCollection: {
      items: [
        {
          sys: {
            id: '41prcsESpADhI9gUtTmhaC',
          },
          content: {
            json: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Contact Support',
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: '855 622 7824',
                      marks: [
                        {
                          type: 'bold',
                        },
                      ],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Monday - Friday',
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: '6am to 5pm PT / 9am to 8pm ET',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          heading: null,
          headingPosition: null,
        },
        {
          sys: {
            id: 'Mz41bPaoBltaIiiG1wmWa',
          },
          content: {
            json: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: 'hyperlink',
                      data: {
                        uri: 'https://support.humaninterest.com/s/contactsupport',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'Contact support online',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          heading: null,
          headingPosition: null,
        },
        {
          sys: {
            id: '6BCYf2qkEbL3VsV0JHB7j3',
          },
          content: {
            json: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Talk to Sales',
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'paragraph',
                  content: [
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [
                        {
                          type: 'bold',
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: 'hyperlink',
                      content: [
                        {
                          nodeType: 'text',
                          value: '877 475 0179',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          data: {},
                        },
                      ],
                      data: {
                        uri: 'tel:1-877-475-0179',
                      },
                    },
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [
                        {
                          type: 'bold',
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
            },
          },
          heading: null,
          headingPosition: null,
        },
      ],
    },
    socialMediaIconsCollection: {
      items: [
        {
          sys: {
            id: '3r2TwGmJjlulWcwwIdns6J',
          },
          title: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/humaninterestco/',
        },
        {
          sys: {
            id: '3YdrCJh6XWXD7RFyM21xaJ',
          },
          title: 'LinkedIn',
          icon: 'linkedin',
          url: 'https://www.linkedin.com/company/humaninterest/',
        },
        {
          sys: {
            id: '5Tv5RphWqoUzqJLl6pqf6e',
          },
          title: 'Twitter',
          icon: 'twitter',
          url: 'https://twitter.com/humaninteresthq',
        },
      ],
    },
    sectionsCollection: {
      items: [
        {
          sys: {
            id: '3l6EwcO7wwZecRFKh3HY3f',
          },
          title: 'Company',
          itemsCollection: {
            items: [
              {
                sys: {
                  id: '4Qx7NfU4A28E6Kp21Poqg2',
                },
                label: 'About',
                url: '/about-us/',
              },
              {
                sys: {
                  id: '2PGpu6swiPuryS7JxT6ZyX',
                },
                label: 'Careers',
                url: '/careers/',
              },
              {
                sys: {
                  id: '2G7NyEvIFGvHQPKXdMdnYG',
                },
                label: 'Pressroom',
                url: '/pressroom/',
              },
              {
                sys: {
                  id: '674WK43gYRzO7y4Zb8n5EV',
                },
                label: 'Security',
                url: 'https://security.humaninterest.com/',
              },
              {
                sys: {
                  id: '4u5sJ0ZNwn2GdrBc6vPPYi',
                },
                label: 'Legal',
                url: '/legal/',
              },
              {
                sys: {
                  id: '1UzTu3SuEORPqsSm9oMwFJ',
                },
                label: 'HIA',
                url: '/hia/',
              },
              {
                sys: {
                  id: '5g3jVNLDQGDd6mqQboHKRf',
                },
                label: 'Investment Advice',
                url: '/hia/investments/',
              },
            ],
          },
        },
        {
          sys: {
            id: 'gpUqb5ZE1aPLMTocmhFza',
          },
          title: 'Solutions',
          itemsCollection: {
            items: [
              {
                sys: {
                  id: 'rmY4R7AMWjWiQydJEqbkz',
                },
                label: '401(k) Plans',
                url: '/solutions/401k-for-small-business/',
              },
              {
                sys: {
                  id: '2sab5jQP1HwyjZUBmal5E5',
                },
                label: '403(b) Plans',
                url: '/solutions/403b-retirement-plans/',
              },
              {
                sys: {
                  id: 'UPJQRFXPGxqjLYhfgtJTd',
                },
                label: 'Safe Harbor',
                url: '/solutions/safe-harbor-401k-plans/',
              },
              {
                sys: {
                  id: '3JeO7NrMNeyrBt8tfWP9AP',
                },
                label: 'IRA',
                url: '/solutions/ira/',
              },
              {
                sys: {
                  id: '245RUMDL0RTzBk21xbHxeu',
                },
                label: 'Get Started',
                url: '/signup/fast-track/',
              },
              {
                sys: {
                  id: '35VUHwzPYsVHgyEwNEm0Nw',
                },
                label: 'Plan Cost Calculator',
                url: '/learn/calculators/plan-cost-calculator/',
              },
              {
                sys: {
                  id: '1lOVfBB6LXvvvChIz1TB8d',
                },
                label: '(k)ickstart™',
                url: '/solutions/kickstart/',
              },
            ],
          },
        },
        {
          sys: {
            id: '2V2E4Mt460oSYanPqOgEYI',
          },
          title: 'Learn',
          itemsCollection: {
            items: [
              {
                sys: {
                  id: '4jwCVkfAroLL4oS3GJW79u',
                },
                label: 'For Employers',
                url: '/learn/employers/',
              },
              {
                sys: {
                  id: 'ReYlaXPUqVsk6sT0CGL2d',
                },
                label: 'For Employees',
                url: '/learn/employees/',
              },
              {
                sys: {
                  id: '43802oMRv29BEr5g6XERVA',
                },
                label: 'Legislation',
                url: '/learn/legislation/',
              },
              {
                sys: {
                  id: 'qdtIUbyeMwghcgPbXhs4d',
                },
                label: 'Webinars',
                url: '/solutions/webinars/',
              },
              {
                sys: {
                  id: '4pCgOJ96cNyVwfILs6XMMU',
                },
                label: 'FAQ',
                url: '/faq/',
              },
            ],
          },
        },
        {
          sys: {
            id: '5vrE6rq0QhbokO9UTa7DgS',
          },
          title: 'Industries',
          itemsCollection: {
            items: [
              {
                sys: {
                  id: '4ECiq8u3gq740gxvY6y5A3',
                },
                label: 'Startups',
                url: '/industries/retirement-plans-for-startups/',
              },
              {
                sys: {
                  id: '47wdtfIitPAcGM7WBWrKDK',
                },
                label: 'Franchises',
                url: '/industries/retirement-plans-for-franchises/',
              },
              {
                sys: {
                  id: '553aTZewY1eO7IlXXDn8Gn',
                },
                label: 'Nonprofits',
                url: '/industries/retirement-plans-for-nonprofits/',
              },
              {
                sys: {
                  id: '2sD2bvY4SFxyWVMHsLUGpS',
                },
                label: 'Restaurants',
                url: '/industries/retirement-plans-for-restaurants/',
              },
              {
                sys: {
                  id: '4qa8O1i5LuuE7iM6aLRHMI',
                },
                label: 'Law Firms',
                url: '/industries/retirement-plans-for-law-firms/',
              },
              {
                sys: {
                  id: '4d6iY2KZAnlh7BdYOmsghV',
                },
                label: 'Dentists',
                url: '/industries/retirement-plans-for-dentists/',
              },
              {
                sys: {
                  id: '2xako0Dww7vgLXdIuodHog',
                },
                label: 'Doctors',
                url: '/industries/retirement-plans-for-doctors/',
              },
            ],
          },
        },
      ],
    },
  },
  headerNavigation: {
    __typename: 'HeaderNav',
    sys: {
      id: '1gVMstnG7cLfENOUM1F25m',
    },
    bgColor: 'white',
    logoUrl: '/',
    mobileLogo: {
      url: 'https://images.ctfassets.net/tj9jxg7kaxby/PGqh8AtQli1FTGu0rhU3S/2f2c4f5dfc70d88584401d39618dfe49/hi-logomark.svg',
      width: 27,
      height: 40,
    },
    desktopLogo: {
      url: 'https://images.ctfassets.net/tj9jxg7kaxby/4xN7JhmwCRuz3y9M8L8CF7/5c244c52cfc982bd1a348a80dc35d43b/hi-logo-new.svg',
      width: 132,
      height: 40,
    },
    secondaryCta: {
      __typename: 'Cta',
      sys: {
        id: '2luIVmAnyvpNmhaPiG2mXu',
      },
      type: 'button',
      variant: 'primary',
      label: 'Get Started',
      url: '/signup/fast-track/',
      openInNewTab: false,
    },
    cta: {
      __typename: 'HeaderNavLoginMenu',
      label: 'Log In',
      linksCollection: {
        items: [
          {
            __typename: 'HeaderNavLink',
            sys: {
              id: 'NiP1sctZhasZvf1nyfbUG',
            },
            image: null,
            badgeBgColor: 'teal20',
            badgeLabel: '401(k)',
            title: 'Work Sponsored',
            url: 'https://app.humaninterest.com/login/',
            description: '401(k) and 403(b)',
          },
          {
            __typename: 'HeaderNavLink',
            sys: {
              id: '7Uxqts7IgJTdtqpCo3kHj',
            },
            image: null,
            badgeBgColor: 'yellow20',
            badgeLabel: 'IRA',
            title: 'Individual',
            url: 'https://ira.humaninterest.com/',
            description: 'IRA accounts and rollovers',
          },
        ],
      },
    },
    navigationItemsCollection: {
      items: [
        {
          __typename: 'Link',
          sys: {
            id: '5Th5cTa07uZBtCom5KE1xy',
          },
          label: 'Pricing',
          url: '/pricing/',
        },
        {
          __typename: 'HeaderNavMenu',
          sys: {
            id: '750DvG0BN967RnymewnrAN',
          },
          title: 'Retirement Solutions',
          sectionsCollection: {
            items: [
              {
                __typename: 'HeaderNavCtaBlock',
                sys: {
                  id: '20Fpffgrp5BTvE5laoUZn',
                },
                backgroundColor: 'brightBlue10',
                heading: '401(k) Plan Cost Calculator',
                paragraph:
                  'Tax credits can add up to $16,500 and are available for three years. See if you qualify.',
                cta: {
                  sys: {
                    id: '4pUpLDjH3vzYU9eha29Wy3',
                  },
                  type: 'button',
                  variant: 'primary',
                  label: 'Calculate Your Savings',
                  url: 'https://humaninterest.com/learn/calculators/plan-cost-calculator/',
                  openInNewTab: false,
                },
              },
              {
                __typename: 'HeaderNavLinkList',
                sys: {
                  id: '2gs0IVor4OkcPhWGO3RggF',
                },
                title: 'Retirement Products',
                seeAllLink: null,
                linksCollection: {
                  items: [
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '3EQ4Hb1CXblubP3tzGXJEX',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: '401(k)',
                      url: '/solutions/401k-for-small-business/',
                      description: 'Get a customizable, affordable 401(k) plan',
                      image: {
                        sys: {
                          id: '29AWC9BuZZeLPkgMpwVwZk',
                        },
                        altText: 'Upward Graph',
                        width: 24,
                        height: 24,
                        image: {
                          title: 'upward-graph',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/MUt1aW75JMK5mJjIEbmsE/685acd8566fd65fcb6ef292de407309f/upward-graph.svg',
                          description: '',
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '4fCfMMntZHaV0l8Yk9WGau',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: '403(b)',
                      url: '/solutions/403b-retirement-plans/',
                      description: 'Retirement plans for tax-exempt businesses',
                      image: {
                        sys: {
                          id: '4LqA1GQJDMeN8T4uIHbQaU',
                        },
                        altText: '403(b) retirement solutions',
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon 403(b)@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/2iVRSUr9vQwaTv2spcz3Q6/10f232d398a37e45cb629077eec606ca/Icon__403_b__3x.png',
                          description: null,
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '6U6C3Ys4zgtLjVgTUOhfk6',
                      },
                      badgeBgColor: 'teal20',
                      badgeLabel: null,
                      title: 'IRA',
                      url: '/solutions/ira/',
                      description: 'Personal accounts with easy 401(k) rollovers',
                      image: {
                        sys: {
                          id: '7K8RVk8b0H2zbelLpehm4H',
                        },
                        altText: 'Safe Harbor Retirement Plans',
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon Safe Harbor@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/34mdnVttt55ot3cpCJPfYP/946aa4d1c3ea6eec3294cad95ba43f49/Icon__Safe_Harbor_3x.png',
                          description: null,
                        },
                      },
                    },
                  ],
                },
              },
              {
                __typename: 'HeaderNavLinkList',
                sys: {
                  id: '1o4sWtjv8IrHMUZjlZLLMK',
                },
                title: 'Our Solution',
                seeAllLink: null,
                linksCollection: {
                  items: [
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '7yeEhz3ju0QAltlNDDtKTW',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Payroll Integrations',
                      url: '/payrolls/',
                      description: 'Reduce manual work with payroll integration',
                      image: {
                        sys: {
                          id: '65H3NWIGnAnY5K67oV4Khy',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon Payroll integration@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/1mmNUmWDmdteBXiszRaLlq/e528e57ec5314cf3f38f158486d2d19a/Icon__Payroll_integration_3x.png',
                          description: null,
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: 'FEqGGtO1BLTFV0soboQsA',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: '(k)ickstart™',
                      url: '/solutions/kickstart/',
                      description:
                        'Participants get 3% cash back in industry-first incentive',
                      image: {
                        sys: {
                          id: '2hdDjKDIZrme5GiJptK267',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: '(k) Logo (1)',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/5UfQeb3Cawc6TmKr0VYIjm/7ffc1b5f3fd27b9019966b76cae5dabf/_k__Logo__1_.png',
                          description: '',
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '229rmJhcORFAu8F68NTVEA',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Customer Reviews from G2',
                      url: '/solutions/g2-401k-reviews/',
                      description:
                        'See why we’re named a leader in the 401(k) software category',
                      image: {
                        sys: {
                          id: '4gEJEZ9VtyV1skFBrdHc2L',
                        },
                        altText: 'g2 human interest star ratings and reviews icon',
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Star Ratings',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/3fNzKIbKzHipDeMfSPvPmL/8482f0de292c3289437e21beb43be6c1/Five-star-badge_-_brand-db.svg',
                          description: '',
                        },
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          __typename: 'HeaderNavMenu',
          sys: {
            id: 'NPIgVNeQYwKQX7U7ez8iN',
          },
          title: 'Payroll Integrations',
          sectionsCollection: {
            items: [
              {
                __typename: 'HeaderNavCtaBlock',
                sys: {
                  id: '4OO8zsE5WpnXBlJ0bxx49s',
                },
                backgroundColor: 'backgroundLightGray2',
                heading: 'We integrate with 450+ leading payrolls.',
                paragraph:
                  'Changing the SMB 401(k) experience through seamless payroll integrations.',
                cta: {
                  sys: {
                    id: '2Zl8yPl9jAoe6HyQXLsuD9',
                  },
                  type: 'button',
                  variant: 'primary',
                  label: 'Find Your Payroll',
                  url: '/payrolls/',
                  openInNewTab: false,
                },
              },
              {
                __typename: 'HeaderNavLinkList',
                sys: {
                  id: 'AanqtqYTrsO2FVc2mgWX8',
                },
                title: 'Top Payroll Integrations',
                seeAllLink: null,
                linksCollection: {
                  items: [
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '1r6x8qBZq5YuYLBxMquJZd',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Gusto',
                      url: '/payrolls/gusto/',
                      description: null,
                      image: {
                        sys: {
                          id: '3iMoOIhoiGyoqbOPxQMinL',
                        },
                        altText: null,
                        width: 16,
                        height: 16,
                        image: {
                          title: 'Gusto Icon@3x_updated',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/4FfBx2iub6QfimaY9WgBHY/3e5bc70f41c78ea6f87e680378807a27/Gusto_Icon_3x__2_.png',
                          description: '',
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: 'JlJbuiXOvlc5nz6AlsZFq',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Heartland',
                      url: '/payrolls/heartland/',
                      description: null,
                      image: {
                        sys: {
                          id: 'xbykImAK9vSKr0B9hSryI',
                        },
                        altText: null,
                        width: 16,
                        height: 16,
                        image: {
                          title: 'Heartland Icon@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/30hmPV8bG9DJqsUGuI1gnO/9ed12fdebe474306572cab5f6157f49f/Heartland_Icon_3x.png',
                          description: null,
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '6Jj3rthoxbWh6IUyVdTjdO',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Namely',
                      url: '/payrolls/namely/',
                      description: null,
                      image: {
                        sys: {
                          id: '7d5weeb9TwdMD9TBB0d20O',
                        },
                        altText: 'Image',
                        width: 16,
                        height: 16,
                        image: {
                          title: 'Namely Logomark',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/16DLDg14hYMR6odPx1HYBq/9d3db1d9eaeb58b3090f0d469f078c9b/Namely.svg',
                          description: '',
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '6XN7K1MYQEdavZeUk8PAiX',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Rippling',
                      url: '/payrolls/rippling/',
                      description: null,
                      image: {
                        sys: {
                          id: '3S6pVK6VL1UUA57xu3krNE',
                        },
                        altText: 'Rippling Logo',
                        width: 21,
                        height: 15,
                        image: {
                          title: 'Rippling Logomark',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/6HUuUUxelPsfXNfNVU2x4c/82473137e8bfcc2820e3a419ae73a386/Rippling.svg',
                          description: '',
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '1RuWYUXZDmutugsMu5qn19',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Paylocity',
                      url: '/payrolls/paylocity/',
                      description: null,
                      image: {
                        sys: {
                          id: '5oT2cv4Gn0RI0S8JQVG1DL',
                        },
                        altText: 'Paylocity',
                        width: 16,
                        height: 16,
                        image: {
                          title: 'Paylocity Logomark',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/4M80oEZvZaNQ0OBl1cFfTh/e6d22a0a361a8d53e0bd4bcd66414d49/Paylocity.svg',
                          description: '',
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '3lepGAbg9VTO37aKdu3E32',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Fingercheck',
                      url: '/payrolls/fingercheck/',
                      description: null,
                      image: {
                        sys: {
                          id: '3GIFTMrKKivDQwD7ClyX1H',
                        },
                        altText: 'Fingercheck',
                        width: 16,
                        height: 16,
                        image: {
                          title: 'Fingercheck Logomark',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/3G8Vr6unUvqc4Owx8xLM31/67b3df2503d6ece77b72975ca8625f0b/Fingercheck.svg',
                          description: '',
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '49cOrxAn7uHGmhRYtfKR7l',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'QuickBooks Online',
                      url: '/payrolls/quickbooksonline/',
                      description: null,
                      image: {
                        sys: {
                          id: '7tw98edx1pqVsPVdNrSnm2',
                        },
                        altText: 'QuickBooks Online Logo',
                        width: 16,
                        height: 15,
                        image: {
                          title: 'QuickBooks Online Logomark',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/6I0DDJtl7LsFFqxcreemKW/a985d4196df828a8f0cd48d5b63ad075/quickbooks-online.svg',
                          description: '',
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '48JowJVQ6WZgiRjQUPnRw6',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Paycor',
                      url: '/payrolls/paycor/',
                      description: null,
                      image: {
                        sys: {
                          id: '5lTgl9i3n9jsbS3IfTDv3F',
                        },
                        altText: null,
                        width: 16,
                        height: 16,
                        image: {
                          title: 'Paycor updated Logo@3x_updated',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/2EmTpn9Pi2OLw9acZXVlt8/5a783c358e5f14823f27301248806156/Paycor_updated_Logo_3x__1_.png',
                          description: '',
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '3BS4fFWWgczCa4nssSKVKu',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'TriNet',
                      url: '/payrolls/trinet-zenefits/',
                      description: null,
                      image: {
                        sys: {
                          id: '2BpZF8eUoj8c3np5Yg26iU',
                        },
                        altText: 'TriNet',
                        width: 11,
                        height: 18,
                        image: {
                          title: 'TriNet Logomark',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/6yqEomrCtW89XrY9nEHycw/0cb3de0ab91d49dab4a07f6ec66c83dd/trinet_sym_2c_rgb_lg.png',
                          description: '',
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '2CeDRMxwEZesI6gwUdWWft',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'BambooHR',
                      url: '/payrolls/bamboohr/',
                      description: null,
                      image: {
                        sys: {
                          id: '55adKtpjjvJQLDvoWAZGby',
                        },
                        altText: null,
                        width: 16,
                        height: 16,
                        image: {
                          title: 'bamboohr-logo',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/2yr44nHQtXNx6lmOYojKBJ/08975d5cdb6a22634909d50ab94a3c94/b-logo-green.png',
                          description: '',
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '1QrJAiVaWRGRDEXgO9yKRV',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'UKG',
                      url: '/payrolls/ukg/',
                      description: null,
                      image: {
                        sys: {
                          id: '1ybf3anee9qMTQTmFh6jQJ',
                        },
                        altText: null,
                        width: 16,
                        height: 16,
                        image: {
                          title: 'UKG Logo U Only',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/2vCnnaFOtJfBzPsfShUWZF/a4cf450b329618cd29e02e0daf760290/UKG_Logo_U_Only_3x.png',
                          description: '',
                        },
                      },
                    },
                    {
                      __typename: 'Link',
                      sys: {
                        id: '3mlKK46vKn2eY6pgwIKofG',
                      },
                      label: 'See All',
                      url: '/payrolls/',
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          __typename: 'HeaderNavMenu',
          sys: {
            id: 'Ry4cVeHQCi7nS1MBmMPAO',
          },
          title: 'Who We Serve',
          sectionsCollection: {
            items: [
              {
                __typename: 'HeaderNavLinkList',
                sys: {
                  id: '5jCfk3vSMzlKWH9Rv1TJbB',
                },
                title: 'Partners',
                seeAllLink: {
                  sys: {
                    id: '4ESsIvtCiQmXAUkB4cecTR',
                  },
                  label: 'See All',
                  url: '/partners/',
                },
                linksCollection: {
                  items: [
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: 'lWBGbUAzbLNKAVLWOElnG',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Accounting Professionals',
                      url: '/partners/accounting-professionals/',
                      description: 'Lower tax liabilities and grow your firm',
                      image: {
                        sys: {
                          id: '3Dgz2VxZXPC43vguZeaJ2D',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon Accounting professionals@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/20YXfWuz08Z1EzRzcc20Rs/a277f7562ab6e808714927b98d790423/Icon__Accounting_professionals_3x.png',
                          description: null,
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '41SOFHyTENqYMwQGcOf1DP',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Benefit Brokers',
                      url: '/partners/benefit-brokers/',
                      description: 'Ensure your clients stay ERISA compliant',
                      image: {
                        sys: {
                          id: '1h19E26Ylz8jUv00HTR4YR',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon Benefit brokers@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/4lXETJETXhSqWjUJkLhK5t/406f9580abbbfdfb3192689b401b426c/Icon__Benefit_brokers_3x.png',
                          description: null,
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '6n7I1wORPHUpJcPckEL8KJ',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Financial Advisors',
                      url: '/partners/financial-advisors/',
                      description: 'Help your SMB clients prepare for their future',
                      image: {
                        sys: {
                          id: '7IgKrSJv4CA4cFez67TlGZ',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon Financial advisors@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/1hpjIrGUPGtxHMosDbzy1m/87803aca1945fd0972eeedc3c055a5fb/Icon__Financial_advisors_3x.png',
                          description: null,
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '3iGXxsL4tYbsRoqn1JusTK',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Banks',
                      url: '/partners/banks/',
                      description: 'Improve your offerings and stay competitive',
                      image: {
                        sys: {
                          id: '7D4tOmXaXTSHC83B8id6ZW',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon Banks@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/5MWnOsTof7rlqNC5Kh9qwF/f6fe75f0c18dc3ab7ff3bce69fb9941c/Icon__Banks_3x.png',
                          description: null,
                        },
                      },
                    },
                  ],
                },
              },
              {
                __typename: 'HeaderNavLinkList',
                sys: {
                  id: '3UUxrv2iq26XJIYwmxOXGG',
                },
                title: 'Industries',
                seeAllLink: null,
                linksCollection: {
                  items: [
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '2PB43ajrh88L93TDdqQGGJ',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Startups',
                      url: '/industries/retirement-plans-for-startups/',
                      description: 'Retirement plans that scale with your startup',
                      image: {
                        sys: {
                          id: 'y6wlQQy16CS0G01p4pSqy',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon Startups@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/8YN5LRRCCEDHR81mKkRwF/a34100a06635a5a6e36809c3019f267f/Icon__Startups_3x.png',
                          description: null,
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '6U5wv0e3eLFSdFY6JjFfiP',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Franchises',
                      url: '/industries/retirement-plans-for-franchises/',
                      description: 'A customer-focused, cost-effective 401(k) plan',
                      image: {
                        sys: {
                          id: '72kasG88rCS9KwueDrUkvF',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon Franchises@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/1At0mMO6KUTjOqgbzlGTaN/e103c7793005f96ab51193533bc5270b/Icon__Franchises_3x.png',
                          description: null,
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '1mfDeL05AAO8o0vxvV1v0K',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Nonprofits',
                      url: '/industries/retirement-plans-for-nonprofits/',
                      description: 'Full-service 401(k) or 403(b) retirement plans',
                      image: {
                        sys: {
                          id: '36pebnNhgfDnlZRzFNfNPY',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon Nonprofits@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/eZqkTbtK1toQ6NJBXwN1k/f427486692c513cebc55963063a93fdf/Icon__Nonprofits_3x.png',
                          description: null,
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '137nNpjnH2dKY6iFXB7Rer',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Restaurants',
                      url: '/industries/retirement-plans-for-restaurants/',
                      description: '401(k)s for both your full and part-time employees',
                      image: {
                        sys: {
                          id: '3BbbYIXCn9smWV1rSlrQO8',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon Restaurants@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/7bAhPPvCeMPupRvguMLceV/1b56ea6b7e896224b509df7c43ee3c17/Icon__Restaurants_3x.png',
                          description: null,
                        },
                      },
                    },
                  ],
                },
              },
              {
                __typename: 'HeaderNavContentSpotlight',
                sys: {
                  id: '5ZmBciIcB6HvX0mXQZ9UgZ',
                },
                backgroundColor: 'yellow10',
                title: 'Customer Stories',
                seeAllLink: {
                  sys: {
                    id: '1sl9fgSU9w9lfeiNPnitSb',
                  },
                  label: 'See All',
                  url: '/customer-stories/',
                },
                linksCollection: {
                  items: [
                    {
                      __typename: 'CustomerStory',
                      sys: {
                        id: '4ntLDgIOaiSMQJNRwLNELr',
                      },
                      slug: 'snap-tire-case-study',
                      title:
                        'How Snap Tire reduced turnover with 401(k) benefits for employees',
                      customerStoryType: 'Case Study',
                      excerpt:
                        'Since implementing a safe harbor 401(k) plan, Snap Tire has seen turnover decrease and has spent more time focusing on improving revenue.',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/15KQxR3wNnHrM5tVikGSpS/699f6719292e1eacc31ea00ade8130f9/Masthead.png',
                      },
                    },
                    {
                      __typename: 'CustomerStory',
                      sys: {
                        id: '5OxFLBQtXgdcTQrsKh35q1',
                      },
                      slug: 'easy-mile-fitness-big-moment',
                      title:
                        'Easy Mile Fitness makes financial wellness a reality for its growing team',
                      customerStoryType: 'Customer Story',
                      excerpt:
                        'At Easy Mile Fitness, (a Planet Fitness franchisee operating 15 locations), health and wellness is about inclusion. Learn how the company takes a similar approach to financial wellness.',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/4JDJLuYs1pmBIKvhQnuHO1/844a39c0f6528b8050c0a90a36d48b89/Easy_Mile_Fitness__1_.jpg',
                      },
                    },
                    {
                      __typename: 'CustomerStory',
                      sys: {
                        id: 'TGxLSpjrQ1n4j7uo2fU0D',
                      },
                      slug: 'nonprofit-ceri',
                      title:
                        'Nonprofit CERI cares for its employees, now and in the future',
                      customerStoryType: 'Customer Story',
                      excerpt:
                        'The Center for Empowering Refugees and Immigrants (CERI) is an Oakland-based nonprofit organization offering mental health and other social services to refugees and immigrants. ',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/4dkJq9l2cjIVrE0so5R3Uh/ba8999ce6dafd53978c9acc2833cce67/CERI_Thumbnail.png',
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          __typename: 'HeaderNavMenu',
          sys: {
            id: '4hAg82KzD5YxPXkjZFMEXu',
          },
          title: 'Learn',
          sectionsCollection: {
            items: [
              {
                __typename: 'HeaderNavLinkList',
                sys: {
                  id: '2UGqcFugdjUMnOkvEZN8gi',
                },
                title: 'Learning Center',
                seeAllLink: {
                  sys: {
                    id: '54mO85cqVAbDACXLj8Vviq',
                  },
                  label: 'See All',
                  url: '/learn/',
                },
                linksCollection: {
                  items: [
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '5mRcSN9G48zAHViwcZXBAp',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'For Employers',
                      url: '/learn/employers/',
                      description: 'Learn more about 401(k) plan administration',
                      image: {
                        sys: {
                          id: '3nWh3xt5ExYrzgEH2Jgi8d',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon For employers@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/ctmPTJxuc4BpEQI5QhOjJ/54f670b6b773a289f919a3359ecbb3a0/Icon__For_employers_3x.png',
                          description: null,
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: '7Aj1ljM8oix9KtWuNiYTSH',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'For Employees',
                      url: '/learn/employees/',
                      description: 'How to get the most from retirement planning',
                      image: {
                        sys: {
                          id: '4G8IqwmYwJNJlhdqle2afT',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon For Employees@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/4TVtarJjM0mM3uKhvJipdO/ef30ef7a6b115b7fd4e95cfdd9f727c3/Icon__For_Employees_3x.png',
                          description: null,
                        },
                      },
                    },
                    {
                      __typename: 'HeaderNavLink',
                      sys: {
                        id: 'pYqwaxuhiDpVccRYglpNU',
                      },
                      badgeBgColor: null,
                      badgeLabel: null,
                      title: 'Retirement Legislation',
                      url: '/learn/legislation/',
                      description: 'Stay updated on the future of retirement policy',
                      image: {
                        sys: {
                          id: '3i0xcyjcCpzBWOhdizeGm7',
                        },
                        altText: null,
                        width: 24,
                        height: 24,
                        image: {
                          title: 'Icon Retirement Legislation@3x',
                          url: 'https://images.ctfassets.net/tj9jxg7kaxby/2lqhKhjDqKcTKCyVL7uUN7/4e14e07f0edd9d549b383b3184d1037a/Icon__Retirement_Legislation_3x.png',
                          description: null,
                        },
                      },
                    },
                  ],
                },
              },
              {
                __typename: 'HeaderNavContentSpotlight',
                sys: {
                  id: '6IQYkO7k2iJzH0j8mgTBQa',
                },
                backgroundColor: 'teal10',
                title: 'Latest Content',
                seeAllLink: {
                  sys: {
                    id: '32ZIS5AGp1fkpA7jIwVIKr',
                  },
                  label: 'See All',
                  url: '/learn/',
                },
                linksCollection: {
                  items: [
                    {
                      __typename: 'LearningCenterArticle',
                      sys: {
                        id: '6ieCqHziq6AAqRNLPEIyWJ',
                      },
                      slug: 'what-is-a-highly-compensated-employee',
                      title: 'What is a highly compensated employee (HCE)?',
                      readingLength: 10,
                      excerpt: null,
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/2YVGbv6EgcfLzwFyT5U257/176bf76fee25476c0efb5af02bc74d8b/shridhar-gupta-dZxQn4VEv2M-unsplash.jpg',
                      },
                    },
                    {
                      __typename: 'LearningCenterGuide',
                      sys: {
                        id: '4OTWbfOkL0HXxYfIcFqWOW',
                      },
                      slug: 'post-pandemic-retirement-playbook',
                      title:
                        'Reimagining the definition of retirement: Meet Pretirement.',
                      excerpt: null,
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/6hIL3ikeGGipkWaKvlQ16T/5cdde003eb126556282d11170b457b8d/will-swann-D-m8wWDa4Lg-unsplash.jpg',
                      },
                    },
                    {
                      __typename: 'LearningCenterArticle',
                      sys: {
                        id: '8wcqyYgIjLV5Qd0SqpzIF',
                      },
                      slug: 'secure-act-2-understanding-proposed-legislation-securing-a-strong-retirement-act',
                      title: 'SECURE Act 2.0: Changes to retirement planning (2023)',
                      readingLength: 18,
                      excerpt:
                        'SECURE Act 2.0, signed into law in December 2022, may help promote retirement savings by mandating auto-enrollment for new plans, boosting tax savings for small employers, and providing more flexibility to savers. ',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/5Whdfw2fuR9ZHHXGo8s72f/1449ace6897d191e1dff81c21c3dd783/kelly-sikkema-wgcUx0kR1ps-unsplash-scaled.jpg',
                      },
                    },
                  ],
                },
              },
              {
                __typename: 'HeaderNavContentSpotlight',
                sys: {
                  id: '30QH7Rvn493n9QpWqgMCIk',
                },
                backgroundColor: 'yellow10',
                title: 'Featured Resources',
                seeAllLink: null,
                linksCollection: {
                  items: [
                    {
                      __typename: 'LearningCenterCalculator',
                      sys: {
                        id: '2VULYfaoooFf5C3WzNTXim',
                      },
                      slug: 'plan-cost-calculator',
                      title: 'The Human Interest Plan Cost Calculator',
                      excerpt:
                        'Could starting a retirement plan qualify you for tax incentives? Do you qualify for SECURE Act 2.0 tax credits? Use this calculator to learn what offering a Human Interest 401(k) might cost.',
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/4BIDefcIDp1T5TvYa205Wc/c179df625e06d62b846fe0bf8e38e2d7/Payroll_Integration_2x.jpg',
                      },
                    },
                    {
                      __typename: 'LearningCenterArticle',
                      sys: {
                        id: '6wbe6TzXX1q2dMEi1Et8vL',
                      },
                      slug: 'what-is-a-state-sponsored-retirement-plan',
                      title: 'What is a state-sponsored retirement plan?',
                      readingLength: 8,
                      excerpt:
                        "Many states have passed legislation regarding retirement plans—and some have upcoming registration deadlines. Learn what's required in your state.",
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/5aqexu9A0eea0dWnMxvkdO/4594b6061bc1518cc671a08c1eb624e3/Frame_3496.png',
                      },
                    },
                    {
                      __typename: 'LearningCenterGuide',
                      sys: {
                        id: '3x4qvZyBHvQohIt7cKoljq',
                      },
                      slug: 'starting-a-small-business-401k',
                      title: 'The ultimate guide to starting a small business 401(k)',
                      excerpt: null,
                      image: {
                        url: 'https://images.ctfassets.net/tj9jxg7kaxby/5Pk2b3d79SzJUYalN0XWtG/07e19dfa5b3b5f131cec3720750a9409/Group_4_2x.png',
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
};
