import styled from 'styled-components';
import css from '@styled-system/css';

import {
  headerNavLinkListDefaultProps,
  headerNavLinkListProps,
} from 'humanity/components/headerNavLinkList/headerNavLinkList.types';
import Box from 'humanity/primitives/box';
import Flex from 'humanity/primitives/flex';
import { HeaderNavMenuLink } from 'humanity/components/headerNavMenuLink';
import HeaderNavMenuHeader from 'humanity/components/headerNavMenuHeader';

const List = styled(Flex)(
  css({
    flexDirection: 'column',
    gap: [2, null, null, 4],
    mt: [3, null, null, 5],
    pb: 2,
  })
);

const WrappedList = styled(Flex)(
  css({
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: [2, null, null, 4],
    mt: [3, null, null, 5],
    pb: 2,
    maxHeight: ['auto', null, null, null, 200],
  })
);

const HeaderNavLinkList = ({ title, seeAllLink, links, ...props }) => (
  <Box {...props}>
    <HeaderNavMenuHeader title={title} seeAllLink={seeAllLink} />
    {links.length <= 6 && (
      <List>
        {links.map((link) => (
          <HeaderNavMenuLink
            key={link.sys?.id}
            image={link.image}
            badgeBgColor={link.badgeBgColor}
            badgeLabel={link.badgeLabel}
            title={link.title ?? link.label}
            url={link.url}
            description={link.description}
          />
        ))}
      </List>
    )}
    {links.length > 6 && (
      <WrappedList>
        {links.map((link) => (
          <HeaderNavMenuLink
            key={link.sys?.id}
            image={link.image}
            badgeBgColor={link.badgeBgColor}
            badgeLabel={link.badgeLabel}
            title={link.title ?? link.label}
            url={link.url}
            description={link.description}
          />
        ))}
      </WrappedList>
    )}
  </Box>
);

HeaderNavLinkList.propTypes = {
  ...headerNavLinkListProps,
};

HeaderNavLinkList.defaultProps = {
  ...headerNavLinkListDefaultProps,
};

export default HeaderNavLinkList;
