import { useContext } from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import { Cross } from 'react-dripicons';
import { color, compose, space } from 'styled-system';

import { PopoverContext } from 'humanity/components/popover/popoverContext';
import Card from 'humanity/components/card';
import TextButton from 'humanity/primitives/textButton';
import Box from 'humanity/primitives/box';
import { popoverContentProps } from 'humanity/components/popover/popover.types';

const Overlay = styled(Box)(({ isOpen }) =>
  css({
    display: isOpen ? 'block' : 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  })
);

const StyledCard = styled(Card)(
  ({ isOpen }) =>
    css({
      visibility: isOpen ? 'visible' : 'hidden',
      position: 'relative',
      p: 5,
      m: 2,
      boxShadow: 'tooltip',
      zIndex: 50,
      cursor: 'default',
    }),
  compose(space, color)
);

const CloseButton = styled(TextButton)(
  css({
    position: 'absolute',
    top: 2,
    right: 2,
    lineHeight: 0,
  })
);

export const PopoverContent = ({ children, ...rest }) => {
  const { getContentProps } = useContext(PopoverContext);

  const { onClose, onKeyDown, isOpen, hideCloseBtn, ...contentProps } =
    getContentProps(rest);

  return (
    <>
      <StyledCard isOpen={isOpen} {...contentProps}>
        {!hideCloseBtn && (
          <CloseButton onClick={onClose} onKeyDown={onKeyDown}>
            <Cross size={24} />
          </CloseButton>
        )}
        {children}
      </StyledCard>
      <Overlay isOpen={isOpen} onClick={onClose} />
    </>
  );
};

PopoverContent.propTypes = {
  ...popoverContentProps,
};
