export default {
  headerNavigation: {
    __typename: 'HeaderNavigation',
    internalTitle: 'Header Nav - Default',
    mobileImage: {
      image: {
        url: 'https://images.ctfassets.net/tj9jxg7kaxby/PGqh8AtQli1FTGu0rhU3S/2f2c4f5dfc70d88584401d39618dfe49/hi-logomark.svg',
      },
      width: 22,
      height: 32,
      altText: 'Human Interest',
    },
    desktopImage: {
      image: {
        url: 'https://images.ctfassets.net/tj9jxg7kaxby/4xN7JhmwCRuz3y9M8L8CF7/5c244c52cfc982bd1a348a80dc35d43b/hi-logo-new.svg',
      },
      width: 132,
      height: 40,
      altText: 'Human Interest',
    },
    linksCollection: {
      items: [
        {
          __typename: 'Link',
          sys: {
            id: '5Th5cTa07uZBtCom5KE1xy',
          },
          label: 'Pricing',
          url: '/pricing/',
        },
        {
          __typename: 'List',
          sys: {
            id: '2WSCwIdYVqqJ3GmyC8hL94',
          },
          title: 'Retirement Solutions',
          itemsCollection: {
            items: [
              {
                sys: {
                  id: 'rmY4R7AMWjWiQydJEqbkz',
                },
                label: '401(k) Plans',
                url: '/solutions/401k-for-small-business/',
              },
              {
                sys: {
                  id: '5kZv6xnZwz9QgMCBSSQHsr',
                },
                label: '403(b) Plans',
                url: '/start-your-403b/',
              },
              {
                sys: {
                  id: '3FxGhCHExbVPWDULwYq1KI',
                },
                label: 'Safe Harbor 401(k)',
                url: '/solutions/safe-harbor-401k-plans/',
              },
              {
                sys: {
                  id: '6Z9eh7gbxO5Ps6CE1b5m49',
                },
                label: 'How It Works',
                url: '/how-it-works/',
              },
              {
                sys: {
                  id: '2mDfnW7qraTLrQHXf8g53U',
                },
                label: 'Investment Philosophy',
                url: '/investment-funds-philosophy/',
              },
            ],
          },
        },
        {
          __typename: 'List',
          sys: {
            id: '3HR85A2PVcyRFATm8p4M10',
          },
          title: 'Payroll Integrations',
          itemsCollection: {
            items: [
              {
                sys: {
                  id: '7uod8WfBqqn9AIliYLqKtV',
                },
                label: 'View All',
                url: '/payrolls/',
              },
              {
                sys: {
                  id: '2DnzkdPzAuc3okr13Yd9DL',
                },
                label: 'Gusto',
                url: '/payrolls/gusto/',
              },
              {
                sys: {
                  id: '5vbdZD67G5PQrohPaUG5F4',
                },
                label: 'Heartland',
                url: '/payrolls/heartland/',
              },
              {
                sys: {
                  id: 'CKtceHCP60vYPNvBl3hcO',
                },
                label: 'Paylocity',
                url: '/payrolls/paylocity/',
              },
              {
                sys: {
                  id: '1nUVxv4w8vRdMztlhsU0X3',
                },
                label: 'QuickBooks Online',
                url: '/payrolls/quickbooksonline/',
              },
            ],
          },
        },
        {
          __typename: 'List',
          sys: {
            id: '6t2NWmGCjYOxBFAITF7D7S',
          },
          title: 'Who We Serve',
          itemsCollection: {
            items: [
              {
                sys: {
                  id: '2Sxi5RivqeGGLkcIFUzAuj',
                },
                label: 'Our Customers',
                url: '/customer-stories/',
              },
              {
                sys: {
                  id: '4MNrsPwzPIwe0N5U2cKIeS',
                },
                label: 'Our Partners',
                url: '/partners/',
              },
            ],
          },
        },
        {
          __typename: 'List',
          sys: {
            id: '9ouvupzifeDn7w0j5GWLa',
          },
          title: 'Learn',
          itemsCollection: {
            items: [
              {
                sys: {
                  id: '49WKXwKkUOiCg0jcpCNj1B',
                },
                label: 'Learning Center',
                url: '/learn/',
              },
              {
                sys: {
                  id: '4jwCVkfAroLL4oS3GJW79u',
                },
                label: 'For Employers',
                url: '/learn/employers/',
              },
              {
                sys: {
                  id: '6w3kqukXRnADb3nVF2CAzO',
                },
                label: 'For Employees',
                url: '/learn/employees/',
              },
              {
                sys: {
                  id: '4zp16D396KfNclm1PDQgT0',
                },
                label: 'Retirement Legislation',
                url: '/learn/legislation/',
              },
            ],
          },
        },
        {
          __typename: 'Cta',
          sys: {
            id: '3q2vliWs3SRJGiAycPvgmH',
          },
          label: 'Log In',
          url: 'https://app.humaninterest.com/login',
          variant: 'outline',
          type: 'button',
        },
        {
          __typename: 'Cta',
          sys: {
            id: '20J22pllN42vrKs3mQGY42',
          },
          label: 'Get Started',
          url: '/signup/',
          variant: 'primary',
          type: 'button',
        },
      ],
    },
  },
  footerNavigation: {
    __typename: 'FooterNavigation',
    contactSectionHeading: "Let's Talk",
    contactSectionBlocksCollection: {
      items: [
        {
          sys: {
            id: '6BCYf2qkEbL3VsV0JHB7j3',
          },
          content: {
            json: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Talk to Sales',
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'paragraph',
                  content: [
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [
                        {
                          type: 'bold',
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: 'hyperlink',
                      content: [
                        {
                          nodeType: 'text',
                          value: '877 475 0179',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          data: {},
                        },
                      ],
                      data: {
                        uri: 'tel:1-877-475-0179',
                      },
                    },
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [
                        {
                          type: 'bold',
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
            },
          },
        },
        {
          sys: {
            id: 'Mz41bPaoBltaIiiG1wmWa',
          },
          content: {
            json: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Need help?',
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'paragraph',
                  content: [
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: 'hyperlink',
                      content: [
                        {
                          nodeType: 'text',
                          value: 'Contact support online',
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {
                        uri: 'https://support.humaninterest.com/s/contactsupport',
                      },
                    },
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
            },
          },
        },
        {
          sys: {
            id: '41prcsESpADhI9gUtTmhaC',
          },
          content: {
            json: {
              nodeType: 'document',
              data: {},
              content: [
                {
                  nodeType: 'paragraph',
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Contact Support',
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'paragraph',
                  content: [
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [
                        {
                          type: 'bold',
                        },
                      ],
                      data: {},
                    },
                    {
                      nodeType: 'hyperlink',
                      content: [
                        {
                          nodeType: 'text',
                          value: '855 622 7824',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          data: {},
                        },
                      ],
                      data: {
                        uri: 'tel:1-855-622-7824',
                      },
                    },
                    {
                      nodeType: 'text',
                      value: '',
                      marks: [
                        {
                          type: 'bold',
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'paragraph',
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Monday - Friday',
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: 'paragraph',
                  content: [
                    {
                      nodeType: 'text',
                      value: '6am to 5pm PT / 9am to 8pm ET',
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
            },
          },
        },
      ],
    },
    socialMediaIconsCollection: {
      items: [
        {
          sys: {
            id: '3r2TwGmJjlulWcwwIdns6J',
          },
          title: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/humaninterestco/',
        },
        {
          sys: {
            id: '3YdrCJh6XWXD7RFyM21xaJ',
          },
          title: 'LinkedIn',
          icon: 'linkedin',
          url: 'https://www.linkedin.com/company/humaninterest/',
        },
        {
          sys: {
            id: '5Tv5RphWqoUzqJLl6pqf6e',
          },
          title: 'Twitter',
          icon: 'twitter',
          url: 'https://twitter.com/humaninteresthq',
        },
      ],
    },
    sectionsCollection: {
      items: [
        {
          sys: {
            id: '3TqqIqXQVk1zuLLdwaX9wy',
          },
          title: 'Why Us?',
          itemsCollection: {
            items: [
              {
                sys: {
                  id: '6Z9eh7gbxO5Ps6CE1b5m49',
                },
                label: 'How It Works',
                url: '/how-it-works/',
              },
              {
                sys: {
                  id: '5Th5cTa07uZBtCom5KE1xy',
                },
                label: 'Pricing',
                url: '/pricing/',
              },
              {
                sys: {
                  id: '4MNrsPwzPIwe0N5U2cKIeS',
                },
                label: 'Our Partners',
                url: '/partners/',
              },
              {
                sys: {
                  id: '5wogUshWi0ciC3CIomQ5mp',
                },
                label: 'Payroll Integrations',
                url: '/payrolls/',
              },
              {
                sys: {
                  id: '2mDfnW7qraTLrQHXf8g53U',
                },
                label: 'Investment Philosophy',
                url: '/investment-funds-philosophy/',
              },
            ],
          },
        },
        {
          sys: {
            id: 'gpUqb5ZE1aPLMTocmhFza',
          },
          title: 'Products',
          itemsCollection: {
            items: [
              {
                sys: {
                  id: 'rmY4R7AMWjWiQydJEqbkz',
                },
                label: '401(k) Plans',
                url: '/solutions/401k-for-small-business/',
              },
              {
                sys: {
                  id: '2sab5jQP1HwyjZUBmal5E5',
                },
                label: '403(b) Plans',
                url: '/start-your-403b/',
              },
              {
                sys: {
                  id: 'UPJQRFXPGxqjLYhfgtJTd',
                },
                label: 'Safe Harbor',
                url: '/solutions/safe-harbor-401k-plans/',
              },
              {
                sys: {
                  id: '245RUMDL0RTzBk21xbHxeu',
                },
                label: 'Get Started',
                url: '/signup/',
              },
            ],
          },
        },
        {
          sys: {
            id: '2V2E4Mt460oSYanPqOgEYI',
          },
          title: 'Learn',
          itemsCollection: {
            items: [
              {
                sys: {
                  id: '3zv4pigrsVWvyUwrmnpkwg',
                },
                label: 'Learning Center',
                url: '/learn/',
              },
              {
                sys: {
                  id: '4jwCVkfAroLL4oS3GJW79u',
                },
                label: 'For Employers',
                url: '/learn/employers/',
              },
              {
                sys: {
                  id: 'ReYlaXPUqVsk6sT0CGL2d',
                },
                label: 'For Employees',
                url: '/learn/employees/',
              },
              {
                sys: {
                  id: '43802oMRv29BEr5g6XERVA',
                },
                label: 'Legislation',
                url: '/learn/legislation/',
              },
              {
                sys: {
                  id: '4pCgOJ96cNyVwfILs6XMMU',
                },
                label: 'FAQ',
                url: '/faq/',
              },
            ],
          },
        },
        {
          sys: {
            id: '4A94Gqu9d0A7SBu8SlG4Cb',
          },
          title: 'Company',
          itemsCollection: {
            items: [
              {
                sys: {
                  id: '4Qx7NfU4A28E6Kp21Poqg2',
                },
                label: 'About',
                url: '/about-us/',
              },
              {
                sys: {
                  id: '2PGpu6swiPuryS7JxT6ZyX',
                },
                label: 'Careers',
                url: '/careers/',
              },
              {
                sys: {
                  id: '2G7NyEvIFGvHQPKXdMdnYG',
                },
                label: 'Pressroom',
                url: '/pressroom/',
              },
              {
                sys: {
                  id: '674WK43gYRzO7y4Zb8n5EV',
                },
                label: 'Security',
                url: '/security/',
              },
              {
                sys: {
                  id: '4u5sJ0ZNwn2GdrBc6vPPYi',
                },
                label: 'Legal',
                url: '/legal/',
              },
            ],
          },
        },
      ],
    },
    copyrightInfo: {
      json: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: '© 2022 Human Interest, Inc. ',
                nodeType: 'text',
              },
              {
                data: {
                  uri: 'https://humaninterest.com/disclosures/',
                },
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Disclosures',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'hyperlink',
              },
              {
                data: {},
                marks: [],
                value: '',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: '655 Montgomery Street, Suite 1800',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [
                  {
                    type: 'code',
                  },
                ],
                value: '<br/>',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [],
                value: 'San Francisco, California 94111',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Human Interest is an affordable, full-service 401(k) and 403(b) provider that seeks to make it easy for small and medium-sized businesses to assist their employees invest for retirement. Investment Advisory services provided through Human Interest Advisors, a Registered Investment Adviser. ',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [],
                value: 'See our ',
                nodeType: 'text',
              },
              {
                data: {
                  uri: 'https://humaninterest.com/terms-of-service/',
                },
                content: [
                  {
                    data: {},
                    marks: [
                      {
                        type: 'underline',
                      },
                    ],
                    value: 'Terms of Service',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'hyperlink',
              },
              {
                data: {},
                marks: [],
                value: ' for additional details. ',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [],
                value:
                  'This content has been prepared for informational purposes only, and should not be construed as tax, legal, or individualized investment advice. Consult an appropriate professional regarding your situation. Human Interest does not provide tax or legal advice. Investing involves risk, including risk of loss. Past performance does not guarantee future results.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
  },
};
