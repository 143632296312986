import styled from 'styled-components';
import { system } from 'styled-system';
import { ComponentProps } from 'react';

import Box from 'humanity/primitives/box';

const Flex = styled(Box)<
  ComponentProps<typeof Box> & {
    gap?: string | number;
    rowGap?: string | number;
    columnGap?: string | number;
  }
>(
  { display: 'flex' },
  system({
    gap: {
      property: 'gap',
      scale: 'space',
    },
    rowGap: {
      property: 'rowGap',
      scale: 'space',
    },
    columnGap: {
      property: 'columnGap',
      scale: 'space',
    },
  })
);

Flex.displayName = 'Flex';

export default Flex;
