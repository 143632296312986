/* eslint-disable import/prefer-default-export */
export const linkFragment = `
  ... on Link {
    sys {
      id
    }
    label
    url
  }
`;
