export const headerNavLoginMenuStandard = {
  label: 'Log In',
  links: [
    {
      sys: {
        id: 'NiP1sctZhasZvf1nyfbUG',
      },
      image: null,
      badgeBgColor: 'teal20',
      badgeLabel: '401(k)',
      title: 'Work Sponsored',
      url: 'https://app.humaninterest.com/login',
      description: '401(k) and 403(b)',
    },
    {
      sys: {
        id: '7Uxqts7IgJTdtqpCo3kHj',
      },
      image: null,
      badgeBgColor: 'yellow20',
      badgeLabel: 'IRA',
      title: 'Individual',
      url: '/',
      description: 'IRA accounts and rollovers',
    },
  ],
};
