import { headerNavContentSpotlightFragment } from 'humanity/components/headerNavContentSpotlight/headerNavContentSpotlight.fragment';
import { headerNavCtaBlockFragment } from 'humanity/components/headerNavCtaBlock/headerNavCtaBlock.fragment';
import { headerNavLinkListFragment } from 'humanity/components/headerNavLinkList/headerNavLinkList.fragment';

/**
 * @type {String}
 */
export const headerNavMenuFragment = `
  ... on HeaderNavMenu {
    sys {
      id
    }
    title
    sectionsCollection(limit: 3) {
      items {
        __typename
        ${headerNavLinkListFragment}
        ${headerNavCtaBlockFragment}
        ${headerNavContentSpotlightFragment}
      }
    }
  }
`;
