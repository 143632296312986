import { poppins, rufina } from 'utils/fonts';

const baseTheme = {
  // 576px, 768px, 992px, 1264px
  breakpoints: ['36em', '48em', '62em', '79em'],
  space: [0, 4, 8, 16, 24, 32, 48, 64, 96, 128, 256, 512],
  sizes: {
    contentWidth: 1200,
    textWidth: 640,
    headerNavMobile: 64,
    headerNavDesktop: 72,
  },
  radii: {
    xxs: 4,
    xs: 6,
    sm: 8,
    md: 16,
    lg: 32,
    circle: '50%',
  },
  fonts: {
    body: poppins.style.fontFamily,
    heading: poppins.style.fontFamily,
    rufina: rufina.style.fontFamily,
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 28, 32, 40, 48, 64],
  fontWeights: {
    body: 400,
    button: 600,
    label: 600,
    link: 600,
    bold: 700,
    heading: 700,
  },
  lineHeights: {
    body: '24px',
    heading: 1.25,
    button: 1,
    h1: '64px',
    h2: '56px',
    h3: '48px',
    h4: '40px',
    h5: '32px',
    h6: '24px',
    subtitle: '24px',
    lead: '32px',
    bodySmall: '20px',
    labelLarge: '24px',
    label: '20px',
    labelSmall: '16px',
    eyebrow: '16px',
    testimonial: '32px',
    testimonialLarge: '48px',
    small: '16px',
  },
  letterSpacings: {
    eyebrow: '1px',
  },
  colors: {
    white: '#FFFFFF',
    black: '#0B0D17',
    gray100: '#0E101E',
    gray90: '#1B1E2D',
    gray80: '#363B49',
    gray70: '#515966',
    gray60: '#6C7682',
    gray50: '#87939F',
    gray40: '#9FA9B2',
    gray30: '#C3C9CF',
    gray20: '#DBDFE2',
    gray10: '#EFF1F4',
    tealDark: '#1C847E',
    teal100: '#14B5AB',
    teal50: '#89DAD5',
    teal40: '#15A29B',
    teal20: '#D0F0EE',
    teal10: '#ECF9F8',
    yellowDark: '#EFAA43',
    yellow100: '#FAC069',
    yellow50: '#FDDFB4',
    yellow20: '#FEF2E1',
    yellow10: '#FFF9F0',
    blueDark: '#041B2F',
    blue100: '#022A4D',
    blue70: '#4E6A82',
    blue50: '#8095A6',
    blue20: '#CCD4DB',
    blue10: '#F3F5F7',
    // why didn't we just have shades darker than the base
    // from the start?
    brightBlueDark: '#0E69B9',
    brightBlue100: '#0585F5',
    brightBlue50: '#82C2FA',
    brightBlue20: '#CDE7FD',
    brightBlue10: '#EBF6FE',
    purpleDark: '#5738B2',
    purple100: '#7254C9',
    purple50: '#9B87D9',
    purple20: '#D8D0F0',
    purple10: '#EAE5FB',
    background: '#FFFFFF',
    backgroundLightGray: '#F9FAFC',
    // :(
    backgroundLightGray2: '#F5F7FA',
    redError: '#E81549',
    hiaCream: '#FFFBEE',
  },
  gradients: {
    blueBlue: 'radial-gradient(100% 2805.09% at 0% 50%, #022A4D 0%, #5F7594 100%)',
    tealTeal: 'radial-gradient(100% 2805.09% at 0% 50%, #164D55 0%, #14B5AB 100%)',
  },
  shadows: {
    card: '0px 10px 20px rgba(41,41,42,0.07)',
    headerNavMenu: '0px 9px 7px rgba(0,0,0,0.03)',
    tooltip: '-2px 4px 45px rgba(0,0,0,0.13)',
    image: '-2px 4px 45px rgba(0,0,0,0.13)',
  },
};

// **** ALIASES ****
export const theme = Object.assign(baseTheme, {
  fontSizes: Object.assign(baseTheme.fontSizes, {
    h1: baseTheme.fontSizes[9],
    h2: baseTheme.fontSizes[8],
    h3: baseTheme.fontSizes[7],
    h4: baseTheme.fontSizes[6],
    h5: baseTheme.fontSizes[5],
    h6: baseTheme.fontSizes[4],
    subtitle: baseTheme.fontSizes[3],
    lead: baseTheme.fontSizes[3],
    body: baseTheme.fontSizes[2],
    bodySmall: baseTheme.fontSizes[1],
    labelLarge: baseTheme.fontSizes[4],
    label: baseTheme.fontSizes[1],
    labelSmall: baseTheme.fontSizes[0],
    eyebrow: baseTheme.fontSizes[1],
    testimonial: baseTheme.fontSizes[3],
    // :(
    testimonialLarge: 32,
    small: baseTheme.fontSizes[0],
  }),
  shadows: {
    ...baseTheme.shadows,
    buttonFocus: `0 0 0 0.25rem ${baseTheme.colors.blue20}`,
  },
  breakpoints: Object.assign(baseTheme.breakpoints, {
    sm: baseTheme.breakpoints[0],
    md: baseTheme.breakpoints[1],
    lg: baseTheme.breakpoints[2],
    xl: baseTheme.breakpoints[3],
  }),
  mediaQueries: {
    sm: `@media screen and (min-width: ${baseTheme.breakpoints[0]})`,
    md: `@media screen and (min-width: ${baseTheme.breakpoints[1]})`,
    lg: `@media screen and (min-width: ${baseTheme.breakpoints[2]})`,
    xl: `@media screen and (min-width: ${baseTheme.breakpoints[3]})`,
  },
});

export type Theme = typeof theme;
