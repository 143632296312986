import PropTypes from 'prop-types';

export const popoverProps = {
  id: PropTypes.string.isRequired,
  placement: PropTypes.oneOf([
    'auto',
    'auto-end',
    'auto-start',
    'bottom',
    'bottom-end',
    'bottom-start',
    'left',
    'left-end',
    'left-start',
    'right',
    'right-end',
    'right-start',
    'top',
    'top-end',
    'top-start',
  ]),
  onShow: PropTypes.func,
  onHide: PropTypes.func,
};

export const popoverDefaultProps = {
  placement: 'auto',
  onShow: () => {},
  onHide: () => {},
};

export const popoverType = PropTypes.shape({
  ...popoverProps,
});

export const popoverContentProps = {
  children: PropTypes.node.isRequired,
};

export const popoverTriggerProps = {
  unstyled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  showIcon: PropTypes.bool,
  iconProps: PropTypes.object,
};
