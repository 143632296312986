/* eslint-disable import/no-named-as-default-member */
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import css from '@styled-system/css';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Facebook, Linkedin, Twitter } from 'react-feather';

import { track } from 'utils/analytics';
import {
  contentfulIconType,
  contentfulLinkType,
  contentfulListType,
  contentfulRichTextType,
} from 'types';
import Section from 'legacy/components/section';
import Flex from 'legacy/components/flex';
import Box from 'legacy/components/box';
import Heading from 'legacy/components/heading';
import Text from 'legacy/components/text';
import Link from 'legacy/components/link';
import NavLink from 'legacy/components/navLink';
import SimpleRichText from 'legacy/components/humanity-v1/primitives/simpleRichText';
import theme from 'utils/theme';

const StyledFooterSection = styled(Box)(
  css({
    mt: [5, null, null, 0],
    ml: [0, null, null, 6],
    width: ['100%', null, '50%', 'auto'],
  })
);

const FooterSectionLinks = styled('ul')(
  css({
    listStyle: 'none',
    color: 'white',
  })
);

export const FooterSection = ({ heading, links, handleTrackClick, handleTrackDown }) => (
  <StyledFooterSection data-testid="FooterSection">
    <Heading as="h3" variant="h5" color="white" mt={0}>
      {heading}
    </Heading>
    <FooterSectionLinks>
      {links.map((link) => (
        <Box as="li" mt="1.5rem" key={link.label}>
          <NavLink
            href={link.url}
            color="white"
            external={link.url.includes('http')}
            onClick={() => handleTrackClick(link.url, link.label)}
            onMouseDown={(e) => handleTrackDown(link.url, link.label, e)}
          >
            {link.label}
          </NavLink>
        </Box>
      ))}
    </FooterSectionLinks>
  </StyledFooterSection>
);

FooterSection.propTypes = {
  heading: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(contentfulLinkType).isRequired,
  handleTrackClick: PropTypes.func.isRequired,
  handleTrackDown: PropTypes.func.isRequired,
};

const YellowLarge = styled('span')(
  css({
    fontSize: 8,
    lineHeight: 0,
    color: 'yellow',
  })
);

const ContactWrapper = styled(Flex)(
  css({
    flexDirection: ['column', null, null, 'row', 'column'],
    flexWrap: ['nowrap', null, null, 'wrap', 'nowrap'],
    mt: [5, null, null, null, 0],
    width: ['100%', null, null, null, '22rem'],
    order: [2, null, null, null, 0],
  })
);

const ContactLink = styled(Link)(
  css({
    color: 'gray100',
    textDecoration: 'none',

    '&:hover': {
      color: 'gray80',
      textDecoration: 'none',
    },
  })
);

const SocialLink = styled(Box)(
  css({
    ml: 4,
    '&:first-child': {
      ml: -2,
    },
  })
);

const LinksWrapper = styled(Flex)(
  css({
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    mt: [-5, null, null, 0],
    ml: [0, null, null, -5, 0],
    width: ['100%', null, null, null, 'auto'],
  })
);

const handleTrackClick = (url, label) => {
  track('bottom_navigation', {
    navigation_text: label,
    navigation_url: url,
  });
};

const handleTrackDown = (url, label, e) => {
  if (e.button > 0) {
    let mouseButton;

    switch (e.button) {
      case 1:
        mouseButton = 'middle_mouse';
        break;
      case 2:
        mouseButton = 'right_mouse';
        break;
      default:
        mouseButton = 'mouse';
    }

    track('bottom_navigation', {
      navigation_text: label,
      navigation_url: url,
      mouse_button: mouseButton,
    });
  }
};

const contactRenderOpts = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Text mt={0} color="gray100">
        {children}
      </Text>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <ContactLink
        href={node?.data?.uri}
        external
        onClick={() => handleTrackClick(node?.data?.uri, node.content?.[0]?.value)}
      >
        {children}
      </ContactLink>
    ),
  },
};

const copyrightRenderOpts = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Text variant="small" color="white">
        {children}
      </Text>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Link
        href={node.data.uri}
        color="white"
        onClick={() => handleTrackClick(node.data.uri, node.content?.[0]?.value)}
      >
        {children}
      </Link>
    ),
  },
};

const Footer = ({
  contactSectionHeading,
  contactSectionBlocks,
  socialMediaIcons,
  linkSections,
  copyrightInfo,
}) => (
  <ThemeProvider theme={theme}>
    <Section as="footer" bg="blue" data-testid="Footer">
      <Section.Inner>
        <Flex flexWrap="wrap">
          <ContactWrapper>
            <Heading color="white" variant="h3" mt={0}>
              {contactSectionHeading}
              <YellowLarge>.</YellowLarge>
            </Heading>
            <Box mt={[2, null, null, -2, 1]} ml={[0, null, null, 7, 0]}>
              {contactSectionBlocks.map((textBlock) => (
                <Box key={textBlock.sys?.id} mt={3}>
                  <SimpleRichText
                    renderOptions={contactRenderOpts}
                    content={textBlock.content?.json}
                  />
                </Box>
              ))}
            </Box>
            <Flex
              mt={[4, null, null, -4, 4]}
              width={['auto', null, null, '100%', 'auto']}
            >
              {socialMediaIcons.map((icon) => {
                let Icon;
                switch (icon.icon) {
                  case 'twitter':
                    Icon = Twitter;
                    break;
                  case 'linkedin':
                    Icon = Linkedin;
                    break;
                  case 'facebook':
                    Icon = Facebook;
                    break;
                  default:
                    Icon = null;
                }
                return (
                  <SocialLink key={icon.sys?.id}>
                    <Link
                      external
                      href={icon.url}
                      aria-label={icon.title}
                      onClick={() => handleTrackClick(icon.url, icon.title)}
                      onMouseDown={(e) => handleTrackDown(icon.url, icon.title, e)}
                    >
                      <Icon
                        size={32}
                        color={theme.colors.gray80}
                        fill={theme.colors.gray80}
                        strokeWidth={0}
                      />
                    </Link>
                  </SocialLink>
                );
              })}
            </Flex>
          </ContactWrapper>
          <LinksWrapper>
            {linkSections.map((section) => (
              <FooterSection
                heading={section.title}
                links={section.itemsCollection.items}
                key={section.sys?.id}
                handleTrackClick={handleTrackClick}
                handleTrackDown={handleTrackDown}
              />
            ))}
          </LinksWrapper>
        </Flex>
        <Box mt={7} mb={[-3, null, null, -6]} textAlign="center">
          <SimpleRichText renderOptions={copyrightRenderOpts} content={copyrightInfo} />
        </Box>
      </Section.Inner>
    </Section>
  </ThemeProvider>
);

Footer.propTypes = {
  contactSectionHeading: PropTypes.string.isRequired,
  contactSectionBlocks: PropTypes.arrayOf(contentfulRichTextType).isRequired,
  socialMediaIcons: PropTypes.arrayOf(contentfulIconType).isRequired,
  linkSections: PropTypes.arrayOf(contentfulListType).isRequired,
  copyrightInfo: contentfulRichTextType.isRequired,
};

export default Footer;
