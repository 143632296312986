import styled from 'styled-components';
import css from '@styled-system/css';

import Box from 'humanity/primitives/box';
import Flex from 'humanity/primitives/flex';
import Image from 'humanity/primitives/image';
import Text from 'humanity/primitives/text';
import Link from 'humanity/primitives/link';
import { headerNavContentLinkProps } from 'humanity/components/headerNavContentLink/headerNavContentLink.types';
import getResourceUrl from 'utils/getResourceUrl';

const StyledLink = styled(Link)({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
});

const Wrapper = styled(Flex)(
  css({
    alignItems: 'center',
    borderRadius: 'sm',
    p: 2,
    '&:hover': {
      bg: 'white',
    },
  })
);

const ImageWrapper = styled(Box)(
  css({
    flexShrink: 0,
    borderRadius: 'sm',
    width: 72,
    height: 72,
    overflow: 'hidden',
    lineHeight: 0,
  })
);

const Title = styled(Text)(
  css({
    fontSize: 'bodySmall',
    lineHeight: 'body',
    fontWeight: 'bold',
  })
);

const HeaderNavContentLink = ({ item }) => {
  const { title, image } = item;
  return (
    <StyledLink href={getResourceUrl(item)}>
      <Wrapper>
        <ImageWrapper>
          <Image
            src={image.url}
            width={72}
            height={72}
            imgixParams={{ fit: 'crop', ar: '1:1' }}
          />
        </ImageWrapper>
        <Box ml={3}>
          <Title>{title}</Title>
        </Box>
      </Wrapper>
    </StyledLink>
  );
};

HeaderNavContentLink.propTypes = {
  ...headerNavContentLinkProps,
};

export default HeaderNavContentLink;
