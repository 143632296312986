import { useId } from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';

import { headerNavLoginMenuProps } from 'humanity/components/headerNavLoginMenu/headerNavLoginMenu.types.js';
import { Popover, PopoverContent, PopoverTrigger } from 'humanity/components/popover';
import Flex from 'humanity/primitives/flex';
import { HeaderNavMenuLink } from 'humanity/components/headerNavMenuLink';
import { useViewport } from 'hooks/useViewport';
import Accordion from 'humanity/components/accordion';

const MobileHeaderNavMenuLink = styled(HeaderNavMenuLink)(
  css({
    borderRadius: 'sm',
    bg: 'backgroundLightGray2',
    p: 3,
  })
);

const HeaderNavLoginMenu = ({ label, links, ...rest }) => {
  const { xl } = useViewport();
  const generatedId = useId();
  const id = `login-menu-${generatedId}`;

  return (
    <>
      {xl && (
        <Popover id={id} placement="bottom-end" hideCloseBtn>
          <PopoverTrigger
            variant="outline"
            btnSize="md"
            showIcon
            iconProps={{ size: 16 }}
            {...rest}
          >
            {label}
          </PopoverTrigger>
          <PopoverContent mr={0}>
            <Flex flexDirection="column" gap={4}>
              {links.map((link) => (
                <HeaderNavMenuLink
                  key={link.sys?.id}
                  image={link.image}
                  badgeBgColor={link.badgeBgColor}
                  badgeLabel={link.badgeLabel}
                  title={link.title ?? link.label}
                  url={link.url}
                  description={link.description}
                />
              ))}
            </Flex>
          </PopoverContent>
        </Popover>
      )}
      {!xl && (
        <Accordion
          baseId={id}
          buttonComponent="Button"
          buttonProps={{ btnSize: 'lg', variant: 'outline', width: 1 }}
          {...rest}
        >
          {label}
          <Flex flexDirection="column" gap={3} mt={3}>
            {links.map((link) => (
              <MobileHeaderNavMenuLink
                key={link.sys?.id}
                image={link.image}
                badgeBgColor={link.badgeBgColor}
                badgeLabel={link.badgeLabel}
                title={link.title ?? link.label}
                url={link.url}
                description={link.description}
                showMobileDescription
              />
            ))}
          </Flex>
        </Accordion>
      )}
    </>
  );
};

HeaderNavLoginMenu.propTypes = {
  ...headerNavLoginMenuProps,
};

export default HeaderNavLoginMenu;
