import { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import Section from 'legacy/components/section';
import Flex from 'legacy/components/flex';
import Text from 'legacy/components/text';
import SimpleRichText from 'legacy/components/humanity-v1/primitives/simpleRichText';
import Box from 'legacy/components/box';
import Link from 'humanity/primitives/link';

const NoteText = styled(Text)(
  css({
    mt: 0,
    ml: 2,
    fontSize: 0,
    color: 'white',
    '& a': {
      color: 'white',
    },
  })
);

const DisclosureText = styled(NoteText)(
  css({
    ml: 0,
  })
);

const noteRenderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <NoteText>{children}</NoteText>,
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri} target="_blank">
        {children}
      </Link>
    ),
  },
};
const disclosureRenderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <DisclosureText ml={0}>{children}</DisclosureText>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri} target="_blank">
        {children}
      </Link>
    ),
  },
};

const DisclaimerSection = ({ notes, disclosures }) => (
  <Section variant="small" bg="blue10">
    <Section.Inner>
      {!!notes.length && (
        <>
          <Text variant="small" color="white" fontWeight={600}>
            Notes
          </Text>
          {notes.map((note, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Flex key={note?.sys?.id ?? index} mt={2}>
              <Text mt={0} fontSize="13px" color="white" fontWeight={600}>
                {index + 1}
              </Text>
              <Box>
                {typeof note === 'string' && (
                  <NoteText dangerouslySetInnerHTML={{ __html: note }} />
                )}
                {typeof note === 'object' && (
                  <SimpleRichText
                    content={note?.content?.json}
                    renderOptions={noteRenderOptions}
                  />
                )}
              </Box>
            </Flex>
          ))}
        </>
      )}
      {!!disclosures.length && (
        <>
          <Text variant="small" color="white" fontWeight={600}>
            Disclosures
          </Text>
          {disclosures.map((disclosure, index) => (
            <Fragment key={disclosure.sys?.id ?? index}>
              {typeof disclosure === 'string' && (
                // eslint-disable-next-line react/no-array-index-key
                <Flex mt={2}>
                  <DisclosureText
                    ml={0}
                    dangerouslySetInnerHTML={{ __html: disclosure }}
                  />
                </Flex>
              )}
              {typeof disclosure === 'object' && (
                <SimpleRichText
                  content={disclosure?.content?.json}
                  renderOptions={disclosureRenderOptions}
                />
              )}
            </Fragment>
          ))}
        </>
      )}
    </Section.Inner>
  </Section>
);

DisclaimerSection.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
    .isRequired,
  disclosures: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
};

export default DisclaimerSection;
