import PropTypes from 'prop-types';

import { contentfulLinkType } from 'types/common/contentfulLink.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';
import { headerNavContentLinkType } from 'humanity/components/headerNavContentLink/headerNavContentLink.types';

const commonProps = {
  backgroundColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  seeAllLink: contentfulLinkType,
};

export const headerNavContentSpotlightProps = {
  ...commonProps,
  links: PropTypes.arrayOf(headerNavContentLinkType).isRequired,
};

export const headerNavContentSpotlightDefaultProps = {
  seeAllLink: null,
};

export const headerNavContentSpotlightType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
  linksCollection: PropTypes.shape({
    items: PropTypes.arrayOf(headerNavContentLinkType).isRequired,
  }).isRequired,
});
