import PropTypes from 'prop-types';

import { contentfulMediaType } from 'types/common/contentfulMedia.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

export const contentfulImageType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  altText: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  image: contentfulMediaType.isRequired,
});
