import { customerStoryPreviewFragment } from 'legacy/graphql/fragments/components/customerStoryPreviewFragment';
import { lcArticlePreviewFragment } from 'legacy/graphql/fragments/components/lcArticlePreviewFragment';
import { lcCalculatorPreviewFragment } from 'legacy/graphql/fragments/components/lcCalculatorPreviewFragment';
import { lcGuidePreviewFragment } from 'legacy/graphql/fragments/components/lcGuidePreviewFragment';

/**
 * @type {String}
 */
export const headerNavContentLinkFragment = `
  __typename
  ${customerStoryPreviewFragment}
  ${lcArticlePreviewFragment}
  ${lcCalculatorPreviewFragment}
  ${lcGuidePreviewFragment}
`;
