import PropTypes from 'prop-types';

import { headerNavContentSpotlightType } from 'humanity/components/headerNavContentSpotlight/headerNavContentSpotlight.types';
import { headerNavCtaBlockType } from 'humanity/components/headerNavCtaBlock/headerNavCtaBlock.types';
import { headerNavLinkListType } from 'humanity/components/headerNavLinkList/headerNavLinkList.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

export const headerNavMenuProps = {
  title: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      headerNavLinkListType,
      headerNavCtaBlockType,
      headerNavContentSpotlightType,
    ])
  ).isRequired,
};

export const headerNavMenuType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  title: PropTypes.string.isRequired,
  sectionsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([
        headerNavLinkListType,
        headerNavCtaBlockType,
        headerNavContentSpotlightType,
      ])
    ),
  }).isRequired,
});
