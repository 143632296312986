import styled, { useTheme } from 'styled-components';
import css from '@styled-system/css';
import { ChevronRight } from 'react-dripicons';

import { headerNavMenuLinkProps } from 'humanity/components/headerNavMenuLink/headerNavMenuLink.types';
import Box from 'humanity/primitives/box';
import Flex from 'humanity/primitives/flex';
import Image from 'humanity/primitives/image';
import Link from 'humanity/primitives/link';
import Text from 'humanity/primitives/text';
import { Badge } from 'humanity/components/badge';
import { constrainImage } from 'utils/constrainImage';

const StyledLink = styled(Link)({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
});

const GridContainer = styled(Box)(({ withBadge }) =>
  css({
    display: 'grid',
    gridTemplateColumns: withBadge ? '52px 1fr' : '24px 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `"icon title" ". description"`,
    columnGap: 3,
    alignItems: 'center',
    borderRadius: 'sm',
    p: 2,
    '&:hover': {
      bg: 'backgroundLightGray2',
    },
  })
);

const ImageContainer = styled(Flex)(
  css({
    gridArea: 'icon',
    justifySelf: 'center',
    mt: '-3px',
  })
);

const BadgeContainer = styled(Box)(
  css({
    gridArea: 'icon',
    justifySelf: 'stretch',
  })
);

const StyledText = styled(Text)(
  css({
    gridArea: 'title',
    fontSize: 'bodySmall',
    fontWeight: 'bold',
    lineHeight: 'body',
    color: 'blue100',
  })
);

const Description = styled(Text)(({ showOnMobile }) =>
  css({
    display: showOnMobile ? 'block' : ['none', null, null, 'block'],
    gridArea: 'description',
    mt: 0,
    fontSize: 'bodySmall',
    fontWeight: 400,
    lineHeight: 'body',
    color: 'blue100',
  })
);

const SeeAllLink = styled(Link)(
  css({
    display: 'flex',
    alignItems: 'center',
    p: 2,
    fontSize: 'bodySmall',
    lineHeight: 'body',
  })
);

const IconWrapper = styled(Flex)(
  css({
    justifyContent: 'center',
    mt: -1,
    ml: 1,
  })
);

const HeaderNavMenuLink = ({
  title,
  url,
  description = '',
  image = null,
  badgeBgColor = 'teal20',
  badgeLabel = '',
  showMobileDescription = false,
  ...rest
}) => {
  const theme = useTheme();
  const fallbackImageSizes = image
    ? constrainImage(image?.image?.width, image?.image?.height, 24, 24)
    : null;

  return (
    <>
      {(image || !!badgeLabel?.length) && (
        <StyledLink href={url} {...rest}>
          <GridContainer withBadge={!image && !!badgeLabel?.length}>
            {image && (
              <ImageContainer>
                <Image
                  src={image.image.url}
                  width={image.width ?? fallbackImageSizes?.width}
                  height={image.height ?? fallbackImageSizes?.height}
                />
              </ImageContainer>
            )}
            {!image && !!badgeLabel?.length && (
              <BadgeContainer>
                <Badge bg={badgeBgColor} label={badgeLabel} css={{ display: 'block' }} />
              </BadgeContainer>
            )}
            <StyledText>{title}</StyledText>
            <Description showOnMobile={showMobileDescription}>{description}</Description>
          </GridContainer>
        </StyledLink>
      )}
      {!image && !badgeLabel?.length && (
        <SeeAllLink href={url} {...rest}>
          {title}
          <IconWrapper>
            <ChevronRight size={16} color={theme.colors.tealDark} />
          </IconWrapper>
        </SeeAllLink>
      )}
    </>
  );
};

HeaderNavMenuLink.displayName = 'HeaderNavMenuLink';

HeaderNavMenuLink.propTypes = {
  ...headerNavMenuLinkProps,
};

export default HeaderNavMenuLink;
