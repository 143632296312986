/**
 * @typedef {Object} ExperimentData
 * @property {string} campaignTitle
 * @property {string} campaignId
 * @property {Object} meta Contains a map of variation keys to Contentful IDs
 * @property {string} campaignKey
 * @property {string} variation The user's assigned variation
 * @property {string} hinAnonymousId Anonymous ID assigned by HI-Next and passed to VWO,
 *                                   used to determine the variation shown
 */

/**
 *
 * @param {Object} vwoClient An instance of vwo-node-sdk
 * @param {Object} entry A Contentful entry that may or may not be a VariationWrapperw
 * @param {string} hinAnonymousId The user's HI-Next anonymous ID, set by utils/getOrSetUserCookie()
 * @param {function(ExperimentData)} experimentCallback A callback fired if an active campaign/experiment was found
 * @param {string} queryVariation An optional forced variation set by the "variation" query parameter
 * @returns Data corresponding to the variation assigned to the user by VWO
 */
const getVwoVariation = (
  vwoClient,
  entry,
  hinAnonymousId,
  experimentCallback,
  queryVariation = null
) => {
  if (entry.__typename === 'VariationWrapper') {
    let variationId;
    const variation =
      queryVariation ?? vwoClient.activate(entry.campaignKey, hinAnonymousId);
    if (!variation) {
      // either the campaign is not running or the settings file is out of date
      // show the control for now
      variationId = entry.meta.Control;
    } else {
      variationId = entry.meta[variation];
      if (typeof experimentCallback === 'function' && !queryVariation) {
        const { campaignTitle, campaignId, meta, campaignKey } = entry;
        experimentCallback({
          campaignTitle,
          campaignId,
          meta,
          campaignKey,
          variation,
          hinAnonymousId,
        });
      }
    }
    const variationToDisplay = entry.variationsCollection.items.find(
      (s) => s.sys.id === variationId
    );
    return variationToDisplay;
  }
  return entry;
};

export default getVwoVariation;
