/**
 * Constrains an image to the given max width and/or height.
 * Returns the original dimensions if smaller than given max width or height
 */
export const constrainImage = (
  intrinsicWidth: number,
  intrinsicHeight: number,
  maxWidth = Infinity,
  maxHeight = Infinity
): { width: number; height: number } => {
  if (intrinsicWidth < maxWidth && intrinsicHeight < maxHeight) {
    return {
      width: intrinsicWidth,
      height: intrinsicHeight,
    };
  }

  const widthPercent = maxWidth / intrinsicWidth;
  const heightPercent = maxHeight / intrinsicHeight;
  const smallestPercent = Math.min(widthPercent, heightPercent);

  return {
    width: intrinsicWidth * smallestPercent,
    height: intrinsicHeight * smallestPercent,
  };
};
