import HeaderNavbarLink from 'humanity/components/headerNavbarLink';
import { headerNavDesktopItemProps } from 'humanity/components/headerNavDesktopItem/headerNavDesktopItem.types.js';
import HeaderNavMenu from 'humanity/components/headerNavMenu';
import Box from 'humanity/primitives/box';

const HeaderNavDesktopItem = ({ type, item, ...props }) => {
  if (type === 'Link') {
    const addProps =
      typeof window !== 'undefined' && item.url === window.location?.pathname
        ? { 'aria-current': 'page' }
        : {};
    return (
      <Box as="li" role="menuitem" key={item.sys?.id} p={3} {...props}>
        <HeaderNavbarLink
          href={item.url}
          data-testid={`HeaderNavDesktopLink - ${item.label}`}
          {...addProps}
        >
          {item.label}
        </HeaderNavbarLink>
      </Box>
    );
  }

  return (
    <HeaderNavMenu
      key={item.sys?.id}
      as="li"
      role="menuitem"
      p={3}
      title={item.title}
      sections={item.sectionsCollection?.items}
      {...props}
    />
  );
};

HeaderNavDesktopItem.propTypes = {
  ...headerNavDesktopItemProps,
};

export default HeaderNavDesktopItem;
