/**
 * @type {String}
 */
export const headerNavMenuLinkFragment = `
  ... on HeaderNavLink {
    sys {
      id
    }
    image {
      sys {
        id
      }
      altText
      width
      height
      image {
        url
        width
        height
      }
    }
    badgeBgColor
    badgeLabel
    title
    url
    description
  }
`;
