import { linkFragment } from 'legacy/graphql/fragments/components/linkFragment';
import { headerNavContentLinkFragment } from 'humanity/components/headerNavContentLink/headerNavContentLink.fragment';

/**
 * @type {String}
 */
export const headerNavContentSpotlightFragment = `
  ... on HeaderNavContentSpotlight {
    sys {
      id
    }
    backgroundColor
    title
    seeAllLink {
      ${linkFragment}
    }
    linksCollection(limit: 3) {
      items {
        __typename
        ${headerNavContentLinkFragment}
      }
    }
  }
`;
