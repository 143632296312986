/* eslint-disable import/prefer-default-export */
/**
 * @type {string}
 */
export const customerStoryPreviewFragment = `
  ... on CustomerStory {
    sys {
        id
    }
    slug
    title
    customerStoryType
    image {
      url
    }
    excerpt
  }
`;
