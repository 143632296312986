import PropTypes from 'prop-types';

import { contentfulMediaType } from 'types/common/contentfulMedia.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

export const learningCenterGuidePreviewType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: contentfulMediaType.isRequired,
  excerpt: PropTypes.string.isRequired,
});
