import { linkFragment } from 'legacy/graphql/fragments/components/linkFragment';
import { headerNavMenuLinkFragment } from 'humanity/components/headerNavMenuLink';

/**
 * @type {String}
 */
export const headerNavLinkListFragment = `
  ... on HeaderNavLinkList {
    sys {
      id
    }
    title
    seeAllLink {
      ${linkFragment}
    }
    linksCollection(limit: 12) {
      items {
        __typename
        ${linkFragment}
        ${headerNavMenuLinkFragment}
      }
    }
  }
`;
