import styled from 'styled-components';
import { useContext } from 'react';
import css from '@styled-system/css';
import { ChevronDown, ChevronUp } from 'react-dripicons';

import { PopoverContext } from 'humanity/components/popover/popoverContext';
import { popoverTriggerProps } from 'humanity/components/popover/popover.types';
import Button from 'humanity/primitives/button';
import Flex from 'humanity/primitives/flex';
import Box from 'humanity/primitives/box';

const StyledDiv = styled.div(
  css({
    cursor: 'pointer',
  })
);

const IconWrapper = styled(Box)(
  css({
    mr: -2,
    lineHeight: 0,
  })
);

export const PopoverTrigger = ({
  unstyled = false,
  showIcon = false,
  iconProps = {},
  children,
  ...rest
}) => {
  const { getTriggerProps, isOpen } = useContext(PopoverContext);
  if (unstyled) {
    return (
      <StyledDiv type="button" {...getTriggerProps(rest)}>
        {children}
      </StyledDiv>
    );
  }
  return (
    <Button type="button" {...getTriggerProps(rest)}>
      {showIcon ? (
        <Flex alignItems="center">
          {children}
          <IconWrapper>
            {isOpen ? <ChevronUp {...iconProps} /> : <ChevronDown {...iconProps} />}
          </IconWrapper>
        </Flex>
      ) : (
        children
      )}
    </Button>
  );
};

PopoverTrigger.propTypes = {
  ...popoverTriggerProps,
};
