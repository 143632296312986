import { useEffect } from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';

import {
  hamburgerDefaultProps,
  hamburgerProps,
} from 'humanity/components/hamburger/hamburger.types';
import TextButton from 'humanity/primitives/textButton';
import Box from 'humanity/primitives/box';

const StyledHamburger = styled(TextButton)(
  css({
    display: 'inline-block',
    transitionProperty: 'opacity,filter',
    transitionDuration: '0.15s',
    transitionTimingFunction: 'linear',
    lineHeight: 0,
    zIndex: 600,
    '&:hover': {
      opacity: 0.85,
    },
  })
);

const HamburgerBox = styled(Box)(
  css({
    position: 'relative',
    display: 'inline-block',
    width: 24,
    height: 20,
  })
);

const HamburgerInner = styled(Box)(({ isActive, bg }) =>
  css({
    position: 'absolute',
    top: '50%',
    mt: '-2px',
    borderRadius: 'sm',
    width: 24,
    height: 4,
    transitionProperty: 'transform',
    transitionDuration: '75ms',
    transitionDelay: isActive ? '120ms' : '0s',
    transitionTimingFunction: isActive
      ? 'cubic-bezier(.215,.61,.355,1)'
      : 'cubic-bezier(.55,.055,.675,.19)',
    transform: isActive ? 'rotate(45deg)' : 'none',
    '&:before, &:after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      borderRadius: 'sm',
      width: 24,
      height: 4,
      bg,
    },
    '&:before': {
      top: isActive ? 0 : '-8px',
      transition: isActive
        ? 'top 75ms ease,opacity 75ms ease .12s'
        : 'top 75ms ease .12s,opacity 75ms ease',
      opacity: isActive ? 0 : 1,
    },
    '&:after': {
      bottom: isActive ? 0 : '-8px',
      transition: isActive
        ? 'bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s'
        : 'bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19)',
      transform: isActive ? 'rotate(-90deg)' : 'none',
    },
  })
);

const Hamburger = ({ color, label, isActive, onClick, ...props }) => {
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  useEffect(() => {
    const handleClose = (e) => {
      if (e.code === 'Escape') {
        if (typeof onClick === 'function') {
          onClick();
        }
      }
    };

    document?.addEventListener('keyup', handleClose);

    return () => document.removeEventListener('keyup', handleClose);
  }, [onClick]);

  return (
    <StyledHamburger
      data-testid="HeaderNavHamburgerButton"
      aria-label={label}
      aria-expanded={isActive}
      onClick={handleClick}
      {...props}
    >
      <HamburgerBox>
        <HamburgerInner bg={color} isActive={isActive} />
      </HamburgerBox>
    </StyledHamburger>
  );
};

Hamburger.propTypes = {
  ...hamburgerProps,
};

Hamburger.defaultProps = {
  ...hamburgerDefaultProps,
};

export default Hamburger;
