import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

// Eventually this should just return the current device size in terms of
// our breakpoints
export const useViewport = () => {
  const theme = useTheme();
  const [state, setState] = useState({ sm: null, md: null, lg: null, xl: null });
  // we have to do some weird stuff here because breakpoints is actually an array
  const { sm, md, lg, xl } = theme.breakpoints;

  useEffect(() => {
    const updateViewport = () => {
      const nextState = {};
      const breakpoints = { sm, md, lg, xl };
      Object.keys(breakpoints).forEach((key) => {
        nextState[key] = window?.matchMedia(`(min-width: ${breakpoints[key]})`).matches;
      });
      setState(nextState);
    };

    updateViewport();

    window?.addEventListener('resize', updateViewport);

    return () => window?.removeEventListener('resize', updateViewport);
  }, [sm, md, lg, xl]);

  return state;
};
