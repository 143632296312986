import NextImage from 'next/image';
import queryString from 'query-string';
import { FC } from 'react';

import imageLoader from 'utils/imageLoader';

export type ImageProps = {
  src: string;
  width?: number | null;
  height?: number | null;
  sizes?: string;
  imgixParams?: Record<string, string | number>;
  alt?: string;
};

const Image: FC<ImageProps> = ({
  src,
  width,
  height,
  sizes,
  imgixParams = {},
  alt = '',
  ...props
}) => {
  if (!src) {
    return null;
  }

  const imgixUrl = src.includes('https')
    ? src.replace(
        `https://images.ctfassets.net/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}`,
        ''
      )
    : src.replace(
        `//images.ctfassets.net/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}`,
        ''
      );
  const params = { auto: 'format,compress', ...imgixParams };
  const query = queryString.stringify(params);
  return (
    <NextImage
      loader={imageLoader}
      src={`${imgixUrl}?${query}`}
      width={width ?? undefined}
      height={height ?? undefined}
      alt={alt}
      sizes={sizes}
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
      {...props}
    />
  );
};

export default Image;
