import PropTypes from 'prop-types';

import { ctaType } from 'humanity/components/cta/cta.types';

export const headerNavCtaBlockProps = {
  backgroundColor: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  cta: ctaType.isRequired,
};

export const headerNavCtaBlockDefaultProps = {
  paragraph: null,
};

export const headerNavCtaBlockType = PropTypes.shape({});
