import { linkFragment } from 'legacy/graphql/fragments/components/linkFragment';
import { ctaFragment } from 'humanity/components/cta/cta.fragment';
import { headerNavLoginMenuFragment } from 'humanity/components/headerNavLoginMenu';
import { headerNavMenuFragment } from 'humanity/components/headerNavMenu/headerNavMenu.fragment';

/**
 * @type {String}
 */
export const headerNavFragment = `
  ... on HeaderNav {
    sys {
      id
    }
    bgColor
    mobileLogo {
      url
      width
      height
    }
    desktopLogo {
      url
      width
      height
    }
    logoUrl
    navigationItemsCollection(limit: 5) {
      items {
        __typename
        ${linkFragment}
        ${headerNavMenuFragment}
      }
    }
    cta {
      __typename
      ${ctaFragment}
      ${headerNavLoginMenuFragment}
    }
    secondaryCta {
      __typename
      ${ctaFragment}
      ${headerNavLoginMenuFragment}
    }
  }
`;
