import { forwardRef } from 'react';

import { BoxProps } from '../box/box';

import Box from 'humanity/primitives/box';
import { theme } from 'utils/theme.v2';

const Container = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <Box
    ref={ref}
    sx={{
      my: 0,
      mx: 'auto',
      px: [4, null, 5],
      width: [
        `calc(${theme.sizes.contentWidth}px + ${theme.space[4]}px * 2)`,
        null,
        `calc(${theme.sizes.contentWidth}px + ${theme.space[5]}px * 2)`,
      ],
      maxWidth: '100%',
    }}
    {...props}
  />
));

export default Container;
