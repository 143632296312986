import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import css from '@styled-system/css';
import { useRouter } from 'next/router';
import Imgix from 'react-imgix';
import { ChevronDown, ChevronRight } from 'react-feather';

import { track } from 'utils/analytics';
import getContentfulImgixUrl from 'utils/getContentfulImgixUrl';
import Flex from 'legacy/components/flex';
import Box from 'legacy/components/box';
import NavLink from 'legacy/components/navLink';
import Cta from 'legacy/components/cta';
import Card from 'legacy/components/card';
import {
  contentfulCtaType,
  contentfulImageType,
  contentfulLinkType,
  contentfulListType,
} from 'types';

const isExternal = (url) => url.includes('http');

const Header = styled(Flex)(
  css({
    justifyContent: 'center',
    bg: 'background',
    boxShadow: 'header',
    width: '100%',
    height: ['navbarHeightMobile', null, null, 'navbarHeightDesktop'],
  })
);

const Nav = styled(Flex).attrs({ as: 'nav' })`
  ${css({
    justifyContent: 'space-between',
    alignItems: 'center',
    mx: ['1.5rem', null, 4, null, 0],
    width: '100%',
    maxWidth: 'contentWidth',
  })}
`;

const DesktopLogo = styled.a`
  display: none;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
`;

const MobileLogo = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: none;
  }
`;

const NavLinks = styled.ul`
  display: none;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;

const NavLinksItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ theme }) => `${theme.space[4]}px`};

  &:first-child {
    margin-left: 0;
  }
`;

const HamburgerIcon = styled.div`
  position: relative;
  display: inline-block;
  overflow: visible;
  margin-right: -1rem;
  padding: 1rem;
  cursor: pointer;
  transition:
    opacity 0.15s,
    filter 0.15s linear;
  z-index: 20;

  &:hover {
    opacity: 0.85;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    display: none;
  }
`;

const HamburgerBox = styled.div`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 24px;
`;

const HamburgerInner = styled.div`
  position: absolute;
  top: 14px;
  width: 30px;
  height: 3px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.blue};
  transition: ${({ isOpen }) =>
    isOpen ? `transform cubic-bezier(.215, .61, .355, 1) .12s` : 'inherit'};
  transform: ${({ isOpen }) => (isOpen ? 'rotate(225deg)' : 'none')};
  z-index: 20;

  &::before,
  &::after {
    position: absolute;
    display: block;
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.blue};
    content: '';
  }

  &::before {
    top: ${({ isOpen }) => (isOpen ? 0 : '-8px')};
    transition: ${({ isOpen }) =>
      isOpen
        ? 'top .1s ease-out, opacity .1s ease-out .12s'
        : 'top .1s ease-in .25s, opacity .1s ease-in'};
  }

  &::after {
    bottom: ${({ isOpen }) => (isOpen ? 0 : '-8px')};
    transition: ${({ isOpen }) =>
      isOpen
        ? 'bottom .1s ease-out, transform .22s cubic-bezier(.215,.61,.355,1) .12s'
        : 'bottom .1s ease-in .25s, transform .22s cubic-bezier(.55, .055, .675, .19)'};
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-90deg)' : 'none')};
  }
`;

const MobileMenuOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  width: 100%;
  height: 100vh;
  min-height: -webkit-fill-available;
  background-color: ${({ theme }) => theme.colors.blue};
  opacity: 0.7;
  z-index: 10;
  transition: opacity 0.6s linear;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: none;
  }
`;

const MobileMenu = styled.div`
  position: absolute;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? 0 : '-85vw')};
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  width: 85vw;
  height: 100vh;
  min-height: -webkit-fill-available;
  background-color: white;
  z-index: 10;
  overflow-y: auto;
  transition: all 0.6s ease-in-out;

  ${({ theme }) => theme.mediaQueries.xl} {
    display: none;
  }
`;

const MobileNavLinks = styled('ul')(
  css({
    mt: 3,
    listStyle: 'none',
  })
);

const MobileNavLinksItem = styled('li')(
  css({
    mt: 4,
  })
);

const Dropdown = styled(NavLinksItem)(
  css({
    position: 'relative',
    '&:hover': {
      '& > div': {
        display: 'block',
      },
    },
  })
);

const Submenu = styled(Card)(
  css({
    display: 'none',
    position: 'absolute',
    top: 4,
    left: 0,
    width: '16rem',
    p: 4,
    bg: 'white',
    boxShadow: 'card',
    zIndex: 50,
    '& > ul': {
      mt: -3,
      listStyle: 'none',
      textAlign: 'left',
    },
  })
);

const SubmenuItem = styled('li')(
  css({
    mt: 3,
  })
);

const MobileDropdownButton = styled(Flex)(
  css({
    display: 'flex',
    alignItems: 'center',
    fontSize: [2, 3],
    fontWeight: 'navigation',
    color: 'blue',
    cursor: 'pointer',
  })
);

const MobileDropdownPanel = styled(Box)(({ isOpen }) =>
  css({
    pl: 3,
    width: isOpen ? 'auto' : 0,
    visibility: isOpen ? 'visible' : 'hidden',
  })
);

const MobileDropdownLink = ({
  dropdownId,
  items,
  onLinkClick,
  handleTrackClick,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useContext(ThemeContext);

  const handleKeyDown = (e) => {
    if (e.repeat) return;
    if (e.key === 'Enter') {
      setIsOpen((x) => !x);
    }
  };
  return (
    <MobileNavLinksItem>
      <MobileDropdownButton
        color="blue"
        fontSize={[2, 3]}
        fontWeight="navigation"
        role="button"
        tabIndex="0"
        onClick={() => setIsOpen((x) => !x)}
        onKeyDown={handleKeyDown}
        id={`mobileSubmenuLink${dropdownId}`}
        aria-expanded={isOpen}
        aria-controls={`mobileSubmenuPanel${dropdownId}`}
        data-testid={`mobileSubmenuLink${dropdownId}`}
      >
        {children}
        <Flex alignItems="center" justifyContent="center" ml={2} height="100%">
          {!isOpen && <ChevronRight size={18} color={theme.colors.blue} />}
          {isOpen && <ChevronDown size={18} color={theme.colors.blue} />}
        </Flex>
      </MobileDropdownButton>
      {/* {isOpen && ( */}
      <MobileDropdownPanel
        isOpen={isOpen}
        id={`mobileSubmenuPanel${dropdownId}`}
        aria-labelledby={`mobileSubmenuLink${dropdownId}`}
        hidden={!isOpen}
        data-testid={`mobileSubmenuPanel${dropdownId}`}
      >
        <MobileNavLinks>
          {items.map((item) => (
            <MobileNavLinksItem key={item.label}>
              <NavLink
                color="blue"
                fontSize={[2, 3]}
                fontWeight="navigation"
                href={item.url}
                external={isExternal(item.url)}
                onClick={() => {
                  onLinkClick();
                  handleTrackClick(item.url, item.label);
                }}
              >
                {item.label}
              </NavLink>
            </MobileNavLinksItem>
          ))}
        </MobileNavLinks>
      </MobileDropdownPanel>
      {/* )} */}
    </MobileNavLinksItem>
  );
};

MobileDropdownLink.propTypes = {
  dropdownId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
      external: PropTypes.bool,
    })
  ).isRequired,
  onLinkClick: PropTypes.func.isRequired,
  handleTrackClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const DropdownLink = ({
  items,
  children,
  onClick,
  handleTrackHover,
  handleTrackDown,
}) => (
  <Dropdown>
    <NavLink color="blue" fontWeight="navigation" href={items[0].url}>
      {children}
    </NavLink>
    <Submenu>
      <ul>
        {items.map((item) => (
          <SubmenuItem key={item.label}>
            <NavLink
              color="blue"
              fontWeight="navigation"
              href={item.url}
              external={item.external}
              onClick={() => onClick(item.url, item.label)}
              onMouseDown={(e) => handleTrackDown(item.url, item.label, e)}
              onMouseEnter={() => handleTrackHover(item.label)}
            >
              {item.label}
            </NavLink>
          </SubmenuItem>
        ))}
      </ul>
    </Submenu>
  </Dropdown>
);

DropdownLink.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
      external: PropTypes.bool,
    })
  ).isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  handleTrackHover: PropTypes.func.isRequired,
  handleTrackDown: PropTypes.func.isRequired,
};

/**
 * @deprecated
 */
const HeaderNav = ({ mobileLogo, desktopLogo, mobileMenuLogo, navItems }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { basePath } = useRouter();
  const ctaLocation = 'header';

  const handleHamburgerKeyDown = (e) => {
    if (e.repeat) return;

    if (e.key === 'Enter') {
      setIsMenuOpen((x) => !x);
    }
  };

  const handleTrackClick = (url, label) => {
    track('top_navigation', {
      name: label,
      target_url: url,
    });
  };

  const handleTrackDown = (url, label, e) => {
    if (e.button > 0) {
      let mouseButton;

      switch (e.button) {
        case 1:
          mouseButton = 'middle_mouse';
          break;
        case 2:
          mouseButton = 'right_mouse';
          break;
        default:
          mouseButton = 'mouse';
      }

      track('top_navigation', {
        name: label,
        target_url: url,
        mouse_button: mouseButton,
      });
    }
  };

  const handleTrackHover = (label) => {
    track('hover', {
      name: label,
    });
  };

  return (
    <Header>
      <Nav>
        <MobileLogo
          href="/"
          onClick={() => handleTrackClick(basePath, mobileLogo.altText)}
          onMouseEnter={() => handleTrackHover(mobileLogo.altText)}
        >
          <Imgix
            src={getContentfulImgixUrl(mobileLogo?.image?.url)}
            width={mobileLogo.width}
            height={mobileLogo.height}
            htmlAttributes={{ alt: mobileLogo.altText }}
          />
        </MobileLogo>
        <HamburgerIcon
          isOpen={isMenuOpen}
          role="button"
          tabIndex="0"
          onClick={() => setIsMenuOpen((x) => !x)}
          onKeyDown={handleHamburgerKeyDown}
          aria-label="Navigation Menu"
          data-testid="HeaderNavMobileHamburgerIcon"
        >
          <HamburgerBox isOpen={isMenuOpen}>
            <HamburgerInner isOpen={isMenuOpen} />
          </HamburgerBox>
        </HamburgerIcon>
        <MobileMenuOverlay isOpen={isMenuOpen} />
        <MobileMenu isOpen={isMenuOpen}>
          <Box px={4} py={3}>
            <Imgix
              src={getContentfulImgixUrl(mobileMenuLogo?.image?.url)}
              width={mobileMenuLogo.width}
              height={mobileMenuLogo.height}
              htmlAttributes={{ alt: mobileMenuLogo.altText }}
            />
            <MobileNavLinks>
              {navItems.map((item) => {
                switch (item.__typename) {
                  case 'Link':
                    return (
                      <MobileNavLinksItem key={item.sys?.id}>
                        <NavLink
                          color="blue"
                          fontSize={[2, 3]}
                          fontWeight="navigation"
                          href={item.url}
                          external={isExternal(item.url)}
                          onClick={() => {
                            setIsMenuOpen(false);
                            handleTrackClick(item.url, item.label);
                          }}
                          data-testid={`HeaderNavMobileLink - ${item.label}`}
                        >
                          {item.label}
                        </NavLink>
                      </MobileNavLinksItem>
                    );
                  case 'Cta':
                    return (
                      <MobileNavLinksItem key={item.sys?.id}>
                        <Cta
                          type="button"
                          label={item.label}
                          url={item.url}
                          location={ctaLocation}
                          btnVariant={item.variant}
                          btnSize="lg"
                          display="inline-block"
                          data-testid={`HeaderNavMobileCta - ${item.label}`}
                        />
                      </MobileNavLinksItem>
                    );
                  case 'List':
                    return (
                      <MobileDropdownLink
                        key={item.sys?.id}
                        dropdownId={item.sys?.id}
                        items={item.itemsCollection?.items}
                        onLinkClick={() => setIsMenuOpen(false)}
                        handleTrackClick={handleTrackClick}
                      >
                        {item.title}
                      </MobileDropdownLink>
                    );
                  default:
                    return null;
                }
              })}
            </MobileNavLinks>
          </Box>
        </MobileMenu>
        <DesktopLogo
          href="/"
          onClick={() => handleTrackClick(basePath, desktopLogo.altText)}
          onMouseEnter={() => handleTrackHover(desktopLogo.altText)}
        >
          <Imgix
            src={getContentfulImgixUrl(desktopLogo?.image?.url)}
            width={desktopLogo.width}
            height={desktopLogo.height}
            htmlAttributes={{ alt: desktopLogo.altText }}
          />
        </DesktopLogo>
        <NavLinks>
          {navItems.map((item) => {
            switch (item.__typename) {
              case 'Link':
                return (
                  <NavLinksItem key={item.sys?.id}>
                    <NavLink
                      color="blue"
                      fontWeight="navigation"
                      href={item.url}
                      external={isExternal(item.url)}
                      onClick={() => handleTrackClick(item.url, item.label)}
                      onMouseDown={(e) => handleTrackDown(item.url, item.label, e)}
                      onMouseEnter={() => handleTrackHover(item.label)}
                      data-testid={`HeaderNavDesktopLink - ${item.label}`}
                    >
                      {item.label}
                    </NavLink>
                  </NavLinksItem>
                );
              case 'Cta':
                return (
                  <NavLinksItem key={item.sys?.id}>
                    <Cta
                      type="button"
                      label={item.label}
                      url={item.url}
                      location={ctaLocation}
                      btnVariant={item.variant}
                      data-testid={`HeaderNavDesktopCta - ${item.label}`}
                    />
                  </NavLinksItem>
                );
              case 'List':
                return (
                  <DropdownLink
                    items={item.itemsCollection?.items}
                    key={item.sys?.id}
                    onClick={handleTrackClick}
                    handleTrackDown={handleTrackDown}
                    handleTrackHover={handleTrackHover}
                  >
                    {item.title}
                  </DropdownLink>
                );
              default:
                return null;
            }
          })}
        </NavLinks>
      </Nav>
    </Header>
  );
};

HeaderNav.propTypes = {
  mobileLogo: contentfulImageType.isRequired,
  desktopLogo: contentfulImageType.isRequired,
  mobileMenuLogo: contentfulImageType.isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.oneOfType([contentfulLinkType, contentfulCtaType, contentfulListType])
  ).isRequired,
};

export default HeaderNav;
