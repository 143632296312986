import { useCallback, useEffect, useState, useId } from 'react';
import { usePopper } from 'react-popper';

export const usePopover = ({ id, placement, onOpen, onClose, hideCloseBtn = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'eventListeners',
        enabled: isOpen,
      },
    ],
  });
  const generatedId = useId();
  const baseId = id ?? generatedId;
  const triggerId = `${baseId}-popover-trigger`;
  const contentId = `${baseId}-popover-content`;

  const handleToggle = useCallback(
    (e) => {
      setIsOpen((currState) => {
        if (currState === false && typeof onOpen === 'function') {
          onOpen(e);
        } else if (typeof onClose === 'function') {
          onClose(e);
        }

        return !currState;
      });
    },
    [onClose, onOpen]
  );

  const handleClose = useCallback(
    (e) => {
      setIsOpen(false);
      if (typeof onClose === 'function') onClose(e);
    },
    [onClose]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (isOpen && e.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose, isOpen]
  );

  const getTriggerProps = useCallback(
    (props = {}) => {
      const triggerProps = {
        ref: setReferenceElement,
        id: triggerId,
        onClick: handleToggle,
        'aria-controls': contentId,
        'aria-expanded': isOpen,
        'aria-haspopup': 'dialog',
        'data-testid': 'PopoverTrigger',
        ...props,
      };

      return triggerProps;
    },
    [triggerId, handleToggle, contentId, isOpen]
  );

  const getContentProps = useCallback(
    (props = {}) => {
      const contentProps = {
        ...attributes.popper,
        style: styles.popper,
        ref: setPopperElement,
        id: contentId,
        role: 'dialog',
        isOpen,
        onClose: handleClose,
        onKeyDown: handleKeyDown,
        hideCloseBtn,
        'aria-labelledby': triggerId,
        'aria-hidden': !isOpen,
        'data-testid': 'PopoverContent',
        ...props,
      };

      return contentProps;
    },
    [
      attributes.popper,
      contentId,
      handleClose,
      handleKeyDown,
      isOpen,
      hideCloseBtn,
      styles.popper,
      triggerId,
    ]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return { getTriggerProps, getContentProps, isOpen };
};
