import styled, { useTheme } from 'styled-components';
import css from '@styled-system/css';
import { ChevronRight } from 'react-dripicons';

import Flex from 'humanity/primitives/flex';
import Text from 'humanity/primitives/text';
import Link from 'humanity/primitives/link';
import { headerNavMenuHeaderProps } from 'humanity/components/headerNavMenuHeader/headerNavMenuHeader.types.js';

const Header = styled(Flex)(
  css({
    justifyContent: 'space-between',
    maxWidth: ['none', null, null, null, 294],
    height: 16,
  })
);

const SeeAllLink = styled(Link)(
  css({
    display: 'flex',
    fontSize: 1,
    lineHeight: '16px',
  })
);

const IconWrapper = styled(Flex)(
  css({
    // alignItems: 'center',
    justifyContent: 'center',
    mt: '-2px',
    ml: 1,
  })
);

const HeaderNavMenuHeader = ({ title, seeAllLink }) => {
  const theme = useTheme();

  return (
    <Header>
      {!!title?.length && (
        <Text variant="eyebrow" fontWeight="bold">
          {title}
        </Text>
      )}
      {!!seeAllLink && (
        <SeeAllLink href={seeAllLink.url}>
          {seeAllLink.label}
          <IconWrapper>
            <ChevronRight size={16} color={theme.colors.tealDark} />
          </IconWrapper>
        </SeeAllLink>
      )}
    </Header>
  );
};

HeaderNavMenuHeader.propTypes = {
  ...headerNavMenuHeaderProps,
};

export default HeaderNavMenuHeader;
