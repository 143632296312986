import PropTypes from 'prop-types';

export const accordionProps = {
  baseId: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  onChange: PropTypes.func,
  expandedByDefault: PropTypes.bool,
  buttonComponent: PropTypes.oneOf(['TextButton', 'Button']),
  buttonProps: PropTypes.object,
  children: PropTypes.node.isRequired,
};
