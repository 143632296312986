import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import css from '@styled-system/css';
import { space } from 'styled-system';

import defaultLayout from 'lib/contentful/fallback/defaultLayout';
import blogLayout from 'lib/contentful/fallback/blogLayout';
import learningCenterLayout from 'lib/contentful/fallback/learningCenterLayout';
import HeaderNav from 'legacy/components/headerNav';
import Footer from 'legacy/components/footer';
import Flex from 'legacy/components/flex';
import DisclaimerSection from 'legacy/components/disclaimerSection';
import CtaSection from 'legacy/components/ctaSection';
import { contentfulLayoutType } from 'types';
import Image from 'humanity/primitives/image';
import HeaderNavV2 from 'humanity/sections/headerNav';
import { useExperiment } from 'hooks/useExperiment';

const Header = styled('header')(
  css({
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 500,
  })
);

const Main = styled('main')(
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mx: 0,
    my: 'auto',
  }),
  space
);

const blogDisclosures = [
  `The content in this blog post has been prepared for informational purposes only, and is not intended to provide, and should not be relied on for, tax, legal or accounting advice. You should consult your own tax, legal and accounting advisors before engaging in any transaction. Human Interest's investment advisory services are provided by Human Interest Advisors, LLC, an SEC-Registered Investment Adviser. Investing involves risk and may result in loss. Past performance is no guarantee of future results, and expected returns may not reflect actual future performance.`,
];

const getLayout = (type, layout) => {
  if (layout) return layout;

  switch (type) {
    case 'primary':
      return defaultLayout;
    case 'secondary':
      return defaultLayout;
    case 'blog':
      return blogLayout;
    case 'learningCenter':
      return learningCenterLayout;
    default:
      return defaultLayout;
  }
};

const Layout = ({
  type = 'primary',
  layout = null,
  notes = [],
  disclosures = [],
  children,
}) => {
  const theme = useTheme();

  const footerDisclosures =
    // eslint-disable-next-line no-nested-ternary
    type === 'blog' ? (disclosures.length ? disclosures : blogDisclosures) : disclosures;

  const { headerNavigation, footerNavigation } = getLayout(type, layout);

  const { variationToDisplay: headerNavVariation } = useExperiment(
    headerNavigation,
    headerNavigation?.variationsCollection?.items?.[0]
  );

  return (
    <>
      {headerNavVariation?.__typename === 'HeaderNavigation' && (
        <Header>
          <HeaderNav
            mobileLogo={headerNavVariation.mobileImage}
            mobileMenuLogo={headerNavVariation.desktopImage}
            desktopLogo={headerNavVariation.desktopImage}
            navItems={headerNavVariation.linksCollection?.items}
          />
        </Header>
      )}
      {headerNavVariation?.__typename === 'HeaderNav' && (
        <HeaderNavV2
          bgColor={headerNavVariation.bgColor}
          mobileLogo={headerNavVariation.mobileLogo}
          desktopLogo={headerNavVariation.desktopLogo}
          logoUrl={headerNavVariation.logoUrl}
          navItems={headerNavVariation.navigationItemsCollection?.items}
          cta={headerNavVariation.cta}
          secondaryCta={headerNavVariation.secondaryCta}
        />
      )}

      <Main
        id="main"
        pt={
          headerNavVariation?.__typename === 'HeaderNavigation'
            ? [
                theme.sizes.navbarHeightMobile,
                null,
                null,
                theme.sizes.navbarHeightDesktop,
              ]
            : 0
        }
      >
        {children}
        {type === 'primary' && (
          <Flex as="section" alignItems="flex-end" width={['auto', null, 1]}>
            <Image
              src="https://images.ctfassets.net/tj9jxg7kaxby/2WAmtTYirTeGfKwekBbDnU/f0b12a43b8b80b13644cd12160855dcb/yellowEllipse.svg"
              width={718}
              height={120}
              alt=""
            />
          </Flex>
        )}
        {type === 'blog' && (
          <CtaSection
            variant="gray"
            title="Small and medium businesses can be prepared, too."
            content="Start a great retirement benefit for less than the cost of one employee's health insurance<sup>1</sup>"
          />
        )}
      </Main>
      {footerNavigation && (
        <Footer
          contactSectionHeading={footerNavigation.contactSectionHeading}
          contactSectionBlocks={footerNavigation.contactSectionBlocksCollection?.items}
          socialMediaIcons={footerNavigation.socialMediaIconsCollection?.items}
          linkSections={footerNavigation.sectionsCollection?.items}
          copyrightInfo={footerNavigation.copyrightInfo?.json}
        />
      )}
      {(!!notes.length || !!footerDisclosures.length) && (
        <DisclaimerSection notes={notes} disclosures={footerDisclosures} />
      )}
    </>
  );
};

Layout.propTypes = {
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'blog',
    'learningCenter',
    'calculator',
    'payroll',
  ]),
  layout: contentfulLayoutType,
  notes: PropTypes.arrayOf(PropTypes.string),
  disclosures: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
};

export default Layout;
