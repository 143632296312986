import PropTypes from 'prop-types';

import { contentfulImageType } from 'types/common/contentfulImage.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

export const headerNavMenuLinkProps = {
  image: contentfulImageType,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string,
  badgeBgColor: PropTypes.string,
  badgeLabel: PropTypes.string,
  showMobileDescription: PropTypes.bool,
};

export const headerNavMenuLinkType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...headerNavMenuLinkProps,
});
