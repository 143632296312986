import styled from 'styled-components';

import Link from 'legacy/components/link';

const NavLink = styled(Link)`
  position: relative;
  text-decoration: none;
  transition: all 0.3s;

  /* Show a nice little animated underline effect on hover */
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -0.3rem;
    background-color: ${({ theme, color }) => theme.colors[color]};
    transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  }
  &:hover::before {
    width: 100%;
  }
`;

export default NavLink;
