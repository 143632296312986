import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';
import NextLink from 'next/link';
import { ChevronRight } from 'react-feather';

import { track } from 'utils/analytics';
import Button from 'humanity/primitives/button';
import Box from 'humanity/primitives/box';
import shouldOpenNewTab from 'utils/shouldOpenNewTab';
import Link from 'humanity/primitives/link';
import { theme } from 'utils/theme.v2';
import { CtaProps, ctaProps } from 'humanity/components/cta/cta.types';
import useAnalytics from 'hooks/useAnalytics';
import useVwo from 'hooks/useVwo';
import { logger } from 'utils/logger';

const IconWrapper = styled(Box)(
  css({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    pl: 1,
    verticalAlign: 'sub',
  })
);

const StyledLink = styled(Link)(
  css({
    display: 'inline-block',
  })
);

const CtaLink = ({ label, url, external, ...props }) => (
  <StyledLink href={url} {...props}>
    {label}
    <IconWrapper as="span">
      <ChevronRight size={19} color={theme.colors.tealDark} />
    </IconWrapper>
  </StyledLink>
);

CtaLink.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  external: PropTypes.bool.isRequired,
};

const EVENT_NAME = 'cta_click';

const Cta = ({
  type = 'button',
  btnSize = 'md',
  btnVariant = 'secondary',
  url = '/signup/',
  label = 'Get Started',
  openInNewTab = false,
  location,
  ...props
}: CtaProps) => {
  const {
    analytics: { hinAnonymousId },
  } = useAnalytics();
  const vwoClient = useVwo();

  const traits = {
    cta_type: location,
    cta_text: label,
    cta_url: url,
  };

  const trackInVwo = () => {
    try {
      const vwoSuccess = vwoClient?.track(null, hinAnonymousId, EVENT_NAME);
      if (!vwoSuccess) {
        logger.error(`Error tracking ${EVENT_NAME} in VWO`, vwoSuccess);
      } else {
        logger.info(`VWO track ${EVENT_NAME}`, vwoSuccess);
      }
    } catch (err) {
      logger.error(`Error tracking ${EVENT_NAME} in VWO`, err);
    }
  };

  const handleCtaClick = () => {
    track(EVENT_NAME, {
      ...traits,
    });
    trackInVwo();
  };

  const handleCtaDown = (e) => {
    if (e.button === 0) return;

    let mouseButton;

    switch (e.button) {
      case 1:
        mouseButton = 'middle_mouse';
        break;
      case 2:
        mouseButton = 'right_mouse';
        break;
      default:
        mouseButton = 'mouse';
    }

    track(EVENT_NAME, {
      ...traits,
      mouse_button: mouseButton,
    });
    trackInVwo();
  };

  const isExternal = openInNewTab ?? shouldOpenNewTab(url);

  if (type === 'button') {
    return isExternal ? (
      <Button
        as="a"
        data-testid="CtaButton"
        href={url}
        rel="noopener"
        target="_blank"
        variant={btnVariant}
        btnSize={btnSize}
        onClick={handleCtaClick}
        onMouseDown={handleCtaDown}
        {...props}
      >
        {label}
      </Button>
    ) : (
      <NextLink passHref href={url} legacyBehavior>
        <Button
          as="a"
          data-testid="CtaButton"
          variant={btnVariant}
          btnSize={btnSize}
          onClick={handleCtaClick}
          onMouseDown={handleCtaDown}
          {...props}
        >
          {label}
        </Button>
      </NextLink>
    );
  }

  if (type === 'link') {
    return (
      <CtaLink
        data-testid="CtaLink"
        label={label}
        url={url}
        external={isExternal}
        onClick={handleCtaClick}
        onMouseDown={handleCtaDown}
        {...props}
      />
    );
  }

  return null;
};

Cta.propTypes = {
  ...ctaProps,
};

export default Cta;
