import PropTypes from 'prop-types';

import { contentfulLinkType } from 'types/common/contentfulLink.types';

export const headerNavMenuHeaderProps = {
  title: PropTypes.string.isRequired,
  seeAllLink: contentfulLinkType.isRequired,
};

export const headerNavMenuHeaderType = PropTypes.shape({
  ...headerNavMenuHeaderProps,
});
