import styled from 'styled-components';
import css from '@styled-system/css';

import Box from 'humanity/primitives/box';
import HeaderNavMenuHeader from 'humanity/components/headerNavMenuHeader';
import {
  headerNavContentSpotlightDefaultProps,
  headerNavContentSpotlightProps,
} from 'humanity/components/headerNavContentSpotlight/headerNavContentSpotlight.types';
import HeaderNavContentLink from 'humanity/components/headerNavContentLink';

// apparently cards just change from page to page, so we get to make a new one each time
const Card = styled(Box)(
  css({
    borderRadius: 'sm',
    p: 4,
  })
);

const LinkWrapper = styled(Box)(
  css({
    mt: 4,
    '&:first-child': {
      mt: 0,
    },
  })
);

const HeaderNavContentSpotlight = ({
  backgroundColor,
  title,
  seeAllLink,
  links,
  ...props
}) => (
  <Box
    sx={{
      display: ['none', null, null, null, 'block'],
    }}
    {...props}
  >
    <HeaderNavMenuHeader title={title} seeAllLink={seeAllLink} />
    <Card mt={5} bg={backgroundColor}>
      {links.map((link) => (
        <LinkWrapper key={link.sys?.id}>
          <HeaderNavContentLink item={link} />
        </LinkWrapper>
      ))}
    </Card>
  </Box>
);

HeaderNavContentSpotlight.propTypes = {
  ...headerNavContentSpotlightProps,
};

HeaderNavContentSpotlight.defaultProps = {
  ...headerNavContentSpotlightDefaultProps,
};

export default HeaderNavContentSpotlight;
