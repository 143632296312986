import styled from 'styled-components';
import {
  variant,
  compose,
  space,
  layout,
  color,
  typography,
  SpaceProps,
  LayoutProps,
  ColorProps,
  TypographyProps,
} from 'styled-system';
import css from '@styled-system/css';

import { VariantProps } from '../../../types/variantProps';

export const textVariants = [
  'subtitle',
  'lead',
  'body',
  'bodySmall',
  'labelLarge',
  'label',
  'labelSmall',
  'eyebrow',
  'testimonial',
  'small',
] as const;

type Props = SpaceProps &
  LayoutProps &
  ColorProps &
  TypographyProps &
  VariantProps<(typeof textVariants)[number]>;

const Text = styled.p<Props>(
  css({
    fontFamily: 'body',
    color: 'blue100',
  }),
  variant({
    variants: {
      subtitle: { fontSize: 'subtitle', lineHeight: 'subtitle' },
      lead: { fontSize: 'lead', lineHeight: 'lead' },
      body: { fontSize: 'body', lineHeight: 'body' },
      bodySmall: { fontSize: 'bodySmall', lineHeight: 'bodySmall' },
      labelLarge: {
        fontSize: 'labelLarge',
        lineHeight: 'labelLarge',
      },
      label: { fontSize: 'label', lineHeight: 'label' },
      labelSmall: {
        fontSize: 'labelSmall',
        lineHeight: 'labelSmall',
      },
      eyebrow: {
        fontSize: 'eyebrow',
        lineHeight: 'eyebrow',
        letterSpacing: '0.07em',
        textTransform: 'uppercase',
      },
      testimonial: {
        fontFamily: 'rufina',
        fontSize: 'testimonial',
        fontWeight: 'bold',
        lineHeight: 'testimonial',
      },
      small: {
        fontSize: 'small',
        lineHeight: 'small',
      },
    },
  }),
  compose(space, layout, color, typography)
);

Text.displayName = 'Text';

export default Text;
