import PropTypes from 'prop-types';

import Box from 'humanity/primitives/box';

const Badge = ({
  label,
  bg = 'teal20',
  color = 'blue100',
  fontSize = '10px',
  ...rest
}) => (
  <Box
    sx={{
      display: 'inline-block',
      borderRadius: 10,
      bg,
      px: 2,
      py: 1,
      textAlign: 'center',
      lineHeight: 1,
    }}
    {...rest}
  >
    <Box
      as="span"
      sx={{
        // not design system values :(
        fontSize,
        lineHeight: '14px',
        fontWeight: 'bold',
        color,
      }}
    >
      {label}
    </Box>
  </Box>
);

Badge.displayName = 'Badge';

export const badgeProps = {
  bg: PropTypes.oneOf(['teal20', 'yellow20']),
  label: PropTypes.string.isRequired,
};

export const badgeType = PropTypes.shape({
  ...badgeProps,
});

Badge.propTypes = {
  ...badgeProps,
};

export default Badge;
