import PropTypes from 'prop-types';

export const hamburgerProps = {
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export const hamburgerDefaultProps = {
  color: 'blue100',
  isActive: false,
  onClick: () => {},
};
