import { popoverProps } from 'humanity/components/popover/popover.types.js';
import { PopoverContext } from 'humanity/components/popover/popoverContext';
import { usePopover } from 'humanity/components/popover/usePopover';

export const Popover = ({
  id,
  placement,
  onShow,
  onHide,
  hideCloseBtn = false,
  children,
}) => {
  const popover = usePopover({ id, placement, onShow, onHide, hideCloseBtn });

  return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
};

Popover.propTypes = {
  ...popoverProps,
};
