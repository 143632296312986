import { headerNavMenuLinkFragment } from 'humanity/components/headerNavMenuLink';

/**
 * @type {String}
 */
export const headerNavLoginMenuFragment = `
  ... on HeaderNavLoginMenu {
    label
    linksCollection(limit: 3) {
      items {
        __typename
        ${headerNavMenuLinkFragment}
      }
    }
  }
`;
