import PropTypes from 'prop-types';

export const headerNavbarLinkProps = {
  color: PropTypes.string,
};

export const headerNavbarLinkDefaultProps = {
  color: 'blue100',
};

export const headerNavbarLinkType = PropTypes.shape({});
