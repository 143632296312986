export const ctaFragment = `
  ... on Cta {
    sys {
      id
    }
    type
    variant
    label
    url
    openInNewTab
  }
`;
