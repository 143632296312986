import { useState } from 'react';
import styled, { css as styledCss, ThemeProvider } from 'styled-components';
import css from '@styled-system/css';

import Container from 'humanity/primitives/container';
import Flex from 'humanity/primitives/flex';
import Link from 'humanity/primitives/link';
import Hamburger from 'humanity/components/hamburger';
import Cta from 'humanity/components/cta';
import Box from 'humanity/primitives/box';
import { headerNavProps } from 'humanity/sections/headerNav/headerNav.types';
import HeaderNavMobileContainer from 'humanity/components/headerNavMobileContainer';
import { fadeIn } from 'utils/animations';
import HeaderNavMobileItem from 'humanity/components/headerNavMobileItem';
import HeaderNavDesktopItem from 'humanity/components/headerNavDesktopItem';
import { theme } from 'utils/theme.v2';
import { HeaderNavLoginMenu } from 'humanity/components/headerNavLoginMenu';
import Image from 'humanity/primitives/image';
import { constrainImage } from 'utils/constrainImage';

const fadeInAnimation = styledCss`
  animation: ${fadeIn} 0.6s ease;
`;

const MobileLogo = styled(Image)(
  css({
    display: ['block', null, null, null, 'none'],
  }),
  fadeInAnimation
);

const DesktopLogo = styled(Image)(
  css({
    display: ['none', null, null, null, 'block'],
  }),
  fadeInAnimation
);

const SkipLink = styled.a(
  css({
    position: 'absolute',
    top: -48,
    left: 0,
    bg: 'blue100',
    color: 'white',
    p: 2,
    zIndex: 100,
    '&:focus': {
      top: 0,
    },
  })
);

const Header = styled(Flex)(
  css({
    position: 'sticky',
    top: 0,
    alignItems: 'center',
    width: '100%',
    height: ['headerNavMobile', null, null, null, 'headerNavDesktop'],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'gray10',
    zIndex: 500,
  })
);

const Inner = styled(Flex)(
  css({
    alignItems: 'center',
    justifyContent: 'space-between',
  })
);

const Left = styled(Flex)(
  css({
    alignItems: 'center',
  })
);

const Right = styled(Flex)(
  css({
    alignItems: 'center',
  })
);

const HomeLink = styled(Link)(
  css({
    display: 'inline-flex',
    alignItems: 'center',
  })
);

const DesktopNav = styled(Flex)(
  css({
    display: ['none', null, null, null, 'flex'],
    alignItems: 'center',
    mx: 3,
  })
);

const DesktopNavList = styled(Flex)(
  css({
    alignItems: 'center',
    listStyle: 'none',
  })
);

const MobileNav = styled(Flex)(
  css({
    display: ['flex', null, null, null, 'none'],
    alignItems: 'center',
  })
);

const MobileNavList = styled(Flex)(
  css({
    flexDirection: 'column',
    width: '100%',
    listStyle: 'none',
  })
);

const DesktopCta = styled(Flex)(
  css({
    display: ['none', null, null, null, 'flex'],
    alignItems: 'center',
    gap: 3,
  })
);

// eslint-disable-next-line react/prop-types
const CtaOrLoginMenu = ({ typename, ...props }) => (
  <>
    {typename === 'Cta' && <Cta location="header_nav" {...props} />}
    {typename === 'HeaderNavLoginMenu' && (
      // eslint-disable-next-line react/prop-types
      <HeaderNavLoginMenu links={props.linksCollection?.items} {...props} />
    )}
  </>
);

const mobileNavId = 'mobile-nav';

const HeaderNav = ({
  bgColor = 'white',
  mobileLogo,
  desktopLogo,
  logoUrl,
  navItems,
  cta,
  secondaryCta,
}) => {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const handleHamburgerClick = () => setShowHamburgerMenu((x) => !x);

  const mobileLogoDimensions = constrainImage(
    mobileLogo.width,
    mobileLogo.height,
    undefined,
    32
  );
  const desktopLogoDimensions = constrainImage(
    desktopLogo.width,
    desktopLogo.height,
    105,
    32
  );

  return (
    <ThemeProvider theme={theme}>
      <Header bg={bgColor} as="header">
        <SkipLink href="#main">Skip to main</SkipLink>
        <Container my="auto">
          <Inner>
            <Left>
              {logoUrl?.length ? (
                <HomeLink href={logoUrl} aria-label="Human Interest Homepage">
                  <MobileLogo
                    src={mobileLogo.url}
                    width={mobileLogoDimensions.width}
                    height={mobileLogoDimensions.height}
                    alt="Human Interest Logo"
                    priority
                  />
                  <DesktopLogo
                    src={desktopLogo.url}
                    width={desktopLogoDimensions.width}
                    height={desktopLogoDimensions.height}
                    alt="Human Interest Logo"
                    priority
                  />
                </HomeLink>
              ) : (
                <span>
                  <MobileLogo
                    src={mobileLogo.url}
                    width={mobileLogoDimensions.width}
                    height={mobileLogoDimensions.height}
                    alt="Human Interest Logo"
                    priority
                  />
                  <DesktopLogo
                    src={desktopLogo.url}
                    width={desktopLogoDimensions.width}
                    height={desktopLogoDimensions.height}
                    alt="Human Interest Logo"
                    priority
                  />
                </span>
              )}
              <DesktopNav as="nav">
                <DesktopNavList as="ul" role="menubar">
                  {navItems?.map((navItem) => (
                    <HeaderNavDesktopItem
                      key={navItem.sys?.id}
                      type={navItem.__typename}
                      item={navItem}
                    />
                  ))}
                </DesktopNavList>
              </DesktopNav>
            </Left>
            <Right>
              <DesktopCta>
                <CtaOrLoginMenu
                  typename={cta.__typename}
                  data-testid={`HeaderNavDesktopCta - ${cta?.label}`}
                  {...cta}
                />
                <CtaOrLoginMenu
                  typename={secondaryCta.__typename}
                  data-testid={`HeaderNavDesktopCta - ${secondaryCta?.label}`}
                  {...secondaryCta}
                />
              </DesktopCta>
              <MobileNav>
                <Hamburger
                  label="Open or Close Navigation Menu"
                  aria-controls={mobileNavId}
                  isActive={showHamburgerMenu}
                  onClick={handleHamburgerClick}
                />
                <HeaderNavMobileContainer
                  isVisible={showHamburgerMenu}
                  onHide={handleHamburgerClick}
                  panelId={mobileNavId}
                >
                  <Box as="nav" px={5}>
                    <MobileNavList as="ul">
                      {navItems.map((navItem) => (
                        <HeaderNavMobileItem
                          key={navItem.sys?.id}
                          type={navItem.__typename}
                          item={navItem}
                        />
                      ))}
                    </MobileNavList>
                    <CtaOrLoginMenu
                      typename={cta.__typename}
                      location="header_nav"
                      btnSize="lg"
                      mt={3}
                      width={1}
                      {...cta}
                    />
                    <CtaOrLoginMenu
                      typename={secondaryCta.__typename}
                      location="header_nav"
                      btnSize="lg"
                      mt={3}
                      width={1}
                      {...secondaryCta}
                    />
                  </Box>
                </HeaderNavMobileContainer>
              </MobileNav>
            </Right>
          </Inner>
        </Container>
      </Header>
    </ThemeProvider>
  );
};

HeaderNav.propTypes = {
  ...headerNavProps,
};

export default HeaderNav;
