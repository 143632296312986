import PropTypes from 'prop-types';

import { headerNavMenuLinkType } from 'humanity/components/headerNavMenuLink/headerNavMenuLink.types';
import { contentfulLinkType } from 'types/common/contentfulLink.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

const commonProps = {
  title: PropTypes.string,
  seeAllLink: contentfulLinkType,
};

export const headerNavLinkListProps = {
  ...commonProps,
  links: PropTypes.arrayOf(
    PropTypes.oneOfType([contentfulLinkType, headerNavMenuLinkType])
  ).isRequired,
};

export const headerNavLinkListDefaultProps = {
  title: '',
  seeAllLink: null,
};

export const headerNavLinkListType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
  linksCollection: PropTypes.shape({
    items: PropTypes.arrayOf(headerNavMenuLinkType).isRequired,
  }),
});
