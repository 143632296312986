import { forwardRef } from 'react';

import Box, { boxProps } from 'humanity/primitives/box';

const TextButton = forwardRef(({ type = 'button', ...rest }, ref) => (
  <Box
    as="button"
    ref={ref}
    type={type}
    sx={{
      m: 0,
      p: 0,
      border: 0,
      borderRadius: 'xs',
      background: 'transparent',
      cursor: 'pointer',
      fontSize: 'inherit',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      color: 'inherit',
      lineHeight: 'inherit',
      textDecoration: 'none',
      textTransform: 'none',
      appearance: 'none',
      userSelect: 'none',
      '&:focus': {
        outline: 0,
        boxShadow: 'buttonFocus',
      },
    }}
    {...rest}
  />
));

TextButton.displayName = 'TextButton';

TextButton.propTypes = {
  ...boxProps,
};

export default TextButton;
