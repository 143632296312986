import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import useVwo from 'hooks/useVwo';
import useAnalytics from 'hooks/useAnalytics';
import getVwoVariation from 'utils/vwo/getVwoVariation';
import { track } from 'utils/analytics';

const handleExperiment = (experimentData) =>
  track('experiment_viewed', {
    ...experimentData,
  });

/**
 *
 * @param {object} entry             A Contentful entry that may or may not be a VariationWrapper
 * @param {object?} defaultVariation A default variation to display to avoid layout shift
 * @returns {object}                 The variation to display for the given entry
 */
export const useExperiment = (entry, defaultVariation = null) => {
  const [variationToDisplay, setVariationToDisplay] = useState(defaultVariation);
  const vwoClient = useVwo();
  const {
    analytics: { hinAnonymousId },
  } = useAnalytics();
  const { query } = useRouter();
  const { variation: queryVariation } = query;

  useEffect(() => {
    if (entry.__typename !== 'VariationWrapper') {
      setVariationToDisplay(entry);
      return;
    }

    if (!vwoClient) return;

    const variation = getVwoVariation(
      vwoClient,
      entry,
      hinAnonymousId,
      handleExperiment,
      queryVariation
    );

    setVariationToDisplay(variation);
  }, [entry, hinAnonymousId, queryVariation, vwoClient]);

  return { variationToDisplay };
};
