import { forwardRef } from 'react';

import Box from 'humanity/primitives/box';

const Card = forwardRef((props, ref) => (
  <Box
    ref={ref}
    sx={{
      bg: 'white',
      borderRadius: 'sm',
      boxShadow: 'card',
    }}
    {...props}
  />
));

export default Card;
