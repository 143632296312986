import styled from 'styled-components';
import css from '@styled-system/css';

import {
  headerNavCtaBlockDefaultProps,
  headerNavCtaBlockProps,
} from 'humanity/components/headerNavCtaBlock/headerNavCtaBlock.types';
import Box from 'humanity/primitives/box';
import Heading from 'humanity/primitives/heading';
import Text from 'humanity/primitives/text';
import Cta from 'humanity/components/cta';

// apparently cards just change from page to page, so we get to make a new one each time
const Card = styled(Box)(
  css({
    display: ['none', null, null, null, 'block'],
    borderRadius: 'sm',
    px: 6,
    py: 5,
  })
);

const HeaderNavCtaBlock = ({ backgroundColor, heading, paragraph, cta, ...props }) => (
  <Card bg={backgroundColor} {...props}>
    <Heading as="h5" variant="h5" dangerouslySetInnerHTML={{ __html: heading.trim() }} />
    {paragraph && (
      <Text
        variant="bodySmall"
        dangerouslySetInnerHTML={{ __html: paragraph.trim() }}
        mt={3}
      />
    )}
    <Cta location="header_nav_menu" {...cta} mt={3} />
  </Card>
);

HeaderNavCtaBlock.propTypes = {
  ...headerNavCtaBlockProps,
};

HeaderNavCtaBlock.defaultProps = {
  ...headerNavCtaBlockDefaultProps,
};

export default HeaderNavCtaBlock;
