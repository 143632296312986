import PropTypes from 'prop-types';

import { contentfulLinkType } from 'types/common/contentfulLink.types';
import { headerNavMenuType } from 'humanity/components/headerNavMenu/headerNavMenu.types';

export const headerNavMobileItemProps = {
  type: PropTypes.oneOf(['Link', 'HeaderNavMenu']).isRequired,
  item: PropTypes.oneOfType([contentfulLinkType, headerNavMenuType]),
};

export const headerNavMobileItemType = PropTypes.shape({});
