import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import css from '@styled-system/css';
import { ChevronDown } from 'react-dripicons';

import { headerNavMenuProps } from 'humanity/components/headerNavMenu/headerNavMenu.types';
import Box from 'humanity/primitives/box';
import Container from 'humanity/primitives/container';
import HeaderNavLinkList from 'humanity/components/headerNavLinkList';
import HeaderNavCtaBlock from 'humanity/components/headerNavCtaBlock';
import Flex from 'humanity/primitives/flex';
import TextButton from 'humanity/primitives/textButton';
import HeaderNavContentSpotlight from 'humanity/components/headerNavContentSpotlight';
import { useViewport } from 'hooks/useViewport';

const HeaderNavbarButton = styled(TextButton)(({ isExpanded }) =>
  css({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    color: 'blue100',
    fontSize: 'bodySmall',
    fontWeight: 'link',
    '&:hover': {
      '&::before': {
        pointerEvents: 'auto',
      },
    },
    '&::before': {
      position: 'absolute',
      top: '80%',
      left: '50%',
      display: 'block',
      width: 70,
      height: 70,
      transform: 'scaleX(6) translateX(-10%) rotate(45deg)',
      zIndex: 0,
      pointerEvents: isExpanded ? 'auto' : 'none',
      content: '""',
    },
  })
);

const IconWrapper = styled(Flex)(({ isExpanded }) =>
  css({
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    pl: 1,
    pb: '2px',
    '& > svg': {
      transform: isExpanded ? 'rotate(180deg)' : 'none',
      transition: 'all 150ms linear',
    },
  })
);

const Menu = styled(Box)(({ isExpanded, theme }) =>
  css({
    position: 'absolute',
    top: [
      `${theme.sizes.headerNavMobile}px`,
      null,
      null,
      `${theme.sizes.headerNavDesktop}px`,
    ],
    left: 0,
    right: 0,
    py: 6,
    boxShadow: 'headerNavMenu',
    bg: 'white',
    opacity: isExpanded ? 1 : 0,
    pointerEvents: isExpanded ? 'auto' : 'none',
    transformOrigin: 'center -20px',
    transform: isExpanded ? 'rotateX(0deg)' : 'rotateX(-15deg)',
    transition: 'transform 250ms ease 0s',
    zIndex: 500,
  })
);

const StyledGrid = styled(Flex)(
  css({
    justifyContent: 'space-between',
    gap: [5, null, null, null, 7],
  })
);

const LinkListWrapper = styled(Box)(({ expandedList }) =>
  css({
    flexShrink: 0,
    flexGrow: expandedList ? 2 : 1,
    flexBasis: expandedList ? 'auto' : '288px',
  })
);

const MobileLinkListWrapper = styled(Box)(
  css({
    mt: 5,
    minWidth: 224,
    width: 'fit-content',
  })
);

const BlockWrapper = styled(Box)(
  css({
    maxWidth: '486px',
  })
);

const HeaderNavMenu = ({ title, sections, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  const { xl: isDesktop } = useViewport();

  const handleOpen = () => setIsExpanded(true);
  const handleClose = () => setIsExpanded(false);
  const handleClick = () => setIsExpanded((x) => !x);

  const expandedList = sections.length < 3;

  return (
    <>
      {isDesktop && (
        <Box
          data-testid={`HeaderNavDesktopMenu - ${title}`}
          onClick={handleClick}
          onFocus={handleOpen}
          onBlur={handleClose}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          position="relative"
          {...props}
        >
          <HeaderNavbarButton
            data-testid={`HeaderNavDesktopMenuButton - ${title}`}
            aria-haspopup="true"
            aria-expanded={isExpanded}
            isExpanded={isExpanded}
          >
            {title}
            <IconWrapper isExpanded={isExpanded}>
              <ChevronDown size={18} color={theme.colors.blue100} />
            </IconWrapper>
          </HeaderNavbarButton>
          <Menu
            data-testid={`HeaderNavDesktopMenuPanel - ${title}`}
            isExpanded={isExpanded}
          >
            <Container>
              <StyledGrid>
                {sections.map((section) => {
                  switch (section.__typename) {
                    case 'HeaderNavLinkList':
                      return (
                        <LinkListWrapper
                          key={section.sys?.id}
                          expandedList={expandedList}
                        >
                          <HeaderNavLinkList
                            title={section.title}
                            seeAllLink={section.seeAllLink}
                            links={section.linksCollection?.items}
                          />
                        </LinkListWrapper>
                      );
                    case 'HeaderNavCtaBlock':
                      return (
                        <BlockWrapper key={section.sys?.id}>
                          <HeaderNavCtaBlock
                            backgroundColor={section.backgroundColor}
                            heading={section.heading}
                            paragraph={section.paragraph}
                            cta={section.cta}
                          />
                        </BlockWrapper>
                      );
                    case 'HeaderNavContentSpotlight':
                      return (
                        <BlockWrapper key={section.sys?.id}>
                          <HeaderNavContentSpotlight
                            backgroundColor={section.backgroundColor}
                            title={section.title}
                            seeAllLink={section.seeAllLink}
                            links={section.linksCollection?.items}
                          />
                        </BlockWrapper>
                      );
                    default:
                      return null;
                  }
                })}
              </StyledGrid>
            </Container>
          </Menu>
        </Box>
      )}
      {isDesktop === false && (
        <Flex
          data-testid={`HeaderNavMobileMenu - ${title}`}
          flexDirection="column"
          ml={5}
        >
          {sections.map((section) => {
            switch (section.__typename) {
              case 'HeaderNavLinkList':
                return (
                  <MobileLinkListWrapper key={section.sys?.id}>
                    <HeaderNavLinkList
                      title={section.title}
                      seeAllLink={section.seeAllLink}
                      links={section.linksCollection?.items}
                    />
                  </MobileLinkListWrapper>
                );
              default:
                return null;
            }
          })}
        </Flex>
      )}
    </>
  );
};

HeaderNavMenu.propTypes = {
  ...headerNavMenuProps,
};

export default HeaderNavMenu;
