/* eslint-disable import/prefer-default-export */
/**
 * @type {string}
 */
export const lcGuidePreviewFragment = `
  ... on LearningCenterGuide {
    sys {
      id
    }
    slug
    title
    image {
      url
    }
    excerpt
  }
`;
