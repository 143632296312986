import styled from 'styled-components';
import css from '@styled-system/css';

import Box from 'humanity/primitives/box';
import { headerNavMobileContainerProps } from 'humanity/components/headerNavMobileContainer/headerNavMobile.types';

const NavContainer = styled(Box)(({ isVisible }) =>
  css({
    position: 'absolute',
    top: 0,
    right: isVisible ? 0 : '-100vw',
    display: isVisible ? 'block' : 'none',
    width: ['calc(100vw - 64px)', null, 'calc(100vw - 82px)'],
    height: '100vh',
    minHeight: '-webkit-fill-available',
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: 'gray10',
    bg: 'white',
    pb: 5,
    zIndex: 500,
    overflowY: 'auto',
    transition: 'all 0.6s ease-in-out',
  })
);

const Top = styled(Box)(
  css({
    position: 'sticky',
    top: 0,
    height: 'headerNavMobile',
    width: '100%',
    bg: 'white',
    zIndex: 550,
  })
);

const Overlay = styled(Box)(({ isVisible }) =>
  css({
    display: isVisible ? 'box' : 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
  })
);

const HeaderNavMobileContainer = ({ isVisible, onHide, panelId, children }) => (
  <>
    <Overlay isVisible={isVisible} onClick={onHide} />
    <NavContainer id={panelId} isVisible={isVisible}>
      <Top />
      {children}
    </NavContainer>
  </>
);

HeaderNavMobileContainer.displayName = 'HeaderNavMobileContainer';

HeaderNavMobileContainer.propTypes = {
  ...headerNavMobileContainerProps,
};

export default HeaderNavMobileContainer;
