import styled from 'styled-components';
import css from '@styled-system/css';

import Accordion from 'humanity/components/accordion';
import Link from 'humanity/primitives/link';
import { headerNavMobileItemProps } from 'humanity/components/headerNavMobileItem/headerNavMobileItem.types';
import HeaderNavMenu from 'humanity/components/headerNavMenu';
import Box from 'humanity/primitives/box';

const baseStyles = {
  display: 'block',
  borderRadius: 'sm',
  cursor: 'pointer',
  fontWeight: 'link',
  color: 'blue100',
  textDecoration: 'none',
};

const StyledAccordion = styled(Accordion)(
  css({
    ...baseStyles,
  })
);

const StyledLink = styled(Link)(
  css({
    ...baseStyles,
    bg: 'backgroundLightGray2',
    p: 3,
    '&:hover': {
      bg: 'gray10',
      textDecoration: 'none',
    },
  })
);

const MobileNavListItem = styled(Box)(
  css({
    mt: 3,
    '&:first-child': {
      mt: 1,
    },
  })
);

const HeaderNavMobileItem = ({ type, item, ...props }) => {
  const addProps =
    typeof window !== 'undefined' && item.url === window.location?.pathname
      ? { 'aria-current': 'page' }
      : {};

  return (
    <MobileNavListItem as="li" {...props}>
      {type === 'Link' && (
        <StyledLink
          data-testid={`HeaderNavMobileLink - ${item.label}`}
          href={item.url}
          {...addProps}
        >
          {item.label}
        </StyledLink>
      )}
      {type === 'HeaderNavMenu' && (
        <StyledAccordion
          baseId={item.sys?.id}
          buttonProps={{
            p: 3,
            bg: 'backgroundLightGray2',
            css: css({
              '&:hover': {
                bg: 'gray10',
                textDecoration: 'none',
              },
            }),
          }}
        >
          {item.title}
          <HeaderNavMenu sections={item.sectionsCollection?.items} />
        </StyledAccordion>
      )}
    </MobileNavListItem>
  );
};

HeaderNavMobileItem.propTypes = {
  ...headerNavMobileItemProps,
};

export default HeaderNavMobileItem;
